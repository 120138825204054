import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import {
  IArchiveDelete,
  ICreateCompany,
  ISearch,
  IUpdateCompany,
} from "../../Interfaces";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class CompanyAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/company");
  }
  async getAllCompanies(): Promise<ApiResponse> {
    const response = await this._api.get(
      "getallcompanies",
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async getCompanyUsers(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getcompanyusers/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async createCompany(payload: ICreateCompany): Promise<ApiResponse> {
    const response = await this._api.post(
      "create",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async updateCompany(payload: IUpdateCompany): Promise<ApiResponse> {
    const response = await this._api.put(
      "update",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async searchCompanies(payload: ISearch): Promise<ApiResponse> {
    const url = `getallcompaniesfiltre/?pageNumber=${payload.pageNumber}&perPage=${payload.perPage}&searchWord=${payload.searchWord}&isArchived=${payload.isArchived}`;
    return await this._api.get(url, handleHeaders(tokensEnum.accessToken));
  }
  async getCompanyById(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getcompanybyid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async archiveCompanies(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.put(
      `archivecompanies`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
  async deleteCompanies(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.delete(
      `deletecompanies`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
}
export { CompanyAPI };
