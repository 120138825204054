import { useRef } from "react"
import { ModalTest } from "../../Modal"
import { ModalRefType } from "../../DesignSystem/Interfaces/Interfaces"
import { useSelector, useDispatch } from "react-redux"
import { IRootState } from "../../Interfaces"
import { useUpdateEffect } from "../../CustomHooks"
import ValidationModal from "../../Modal/ValidationModal/ValidationModal"
import GenericModal from "../../Modal/GenericModal/GenericModal"
import { closeModal } from "../../Redux/Reducers"
function ModalProvider() {
  const { name, data } = useSelector((state: IRootState) => state?.modal)
  const modalName = useRef("")
  const modalTestRef = useRef<ModalRefType>()
  const validationModalRef = useRef<ModalRefType>()
  const genericModalRef = useRef<ModalRefType>()
  const dispatch = useDispatch()

  const onExit = (key: string) => {
    switch (key) {
      case "modalTestRef":
        modalTestRef.current?.onClose()
        break
      case "validationModalRef":
        validationModalRef.current?.onClose()
        break
      case "genericModalRef":
        genericModalRef.current?.onClose()
        break
      default:
        break
    }
    dispatch(closeModal())
    modalName.current = ""
  }
  const onShow = (key: string) => {
    modalName.current = key
    switch (key) {
      case "modalTestRef":
        modalTestRef.current?.onOpen()
        break
      case "validationModalRef":
        validationModalRef.current?.onOpen()
        break
      case "genericModalRef":
        genericModalRef.current?.onOpen()
        break
      default:
        break
    }
  }
  useUpdateEffect(() => {
    if (name) {
      if (modalName.current === "") {
        onShow(name)
      }
    }
  }, [name])
  return (
    <>
      <ModalTest
        ref={modalTestRef}
        data={data}
        onExit={() => onExit("modalTestRef")}
        onShow={() => onShow("modalTestRef")}
      />
      <ValidationModal
        ref={validationModalRef}
        data={data}
        onExit={() => onExit("validationModalRef")}
        onShow={() => onShow("validationModalRef")}
      />
      <GenericModal
        ref={genericModalRef}
        data={data}
        onExit={() => onExit("genericModalRef")}
        onShow={() => onShow("genericModalRef")}
      />
    </>
  )
}
export default ModalProvider
