import { useEffect, useState } from "react";
import { ManageCompanyTemplate } from "../../Templates";
import { ICreateCompany } from "../../Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyAPI } from "../../Apis";
import { store } from "../../Redux/Store/Store";
import { setEmitter } from "../../Redux/Reducers";
import { Httpstatus } from "@piximind/api";
import { ApiResponse } from "@piximind/api/lib/types";
import { Data } from "../../Common";
import { LangContext } from "../../Lang/Provider/Provider";
import React from "react";

export const ManageCompany = () => {
  const lang = React.useContext(LangContext);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [isUpdateCompany, setIsUpdateCompany] = useState<boolean>(false);
  const companyApi = new CompanyAPI();
  const selectDataYears = Data.getDataYears();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyManager, setCompanyManager] = useState<ICreateCompany>({
    name: "",
    registrationNumber: 0,
    subscriptionStartDate: new Date(),
    subscriptionEndDate: new Date(),
    rmq: {
      firstName: "",
      lastName: "",
      mail: "",
      password: "",
      role: "",
      phoneNumber: "",
      isRmq: true,
    },
    manager: {
      firstName: "",
      lastName: "",
      mail: "",
      password: "",
      role: "",
      phoneNumber: "",
      isRmq: true,
    },
    yearsName: [],
  });
  useEffect(() => {
    verifId();
  }, []);
  const verifId = async () => {
    if (id != "" || undefined) {
      try {
        const response = await companyApi.getCompanyById(id!);
        if (response.status === 200) {
          setStep(3);
          setIsUpdateCompany(true);
          const company: ICreateCompany = response?.data.company;
          const years: { _id: string; name: string }[] =
            response?.data.company?.yearsId;
          setCompanyManager({
            ...company,
            yearsName: years.map((year) => year.name),
          });
        } else {
          navigate("/managecompany");
        }
      } catch (err) {
        isUpdateCompany &&
          store.dispatch(
            setEmitter({
              type: "error",
              message: lang.error,
              position: "top-center",
            })
          );
      }
    }
  };
  const handleCreateUpdateCompany = async () => {
    setIsLoading(true);
    let response: ApiResponse;
    let stringRegistrationNumber: string =
      companyManager.registrationNumber.toString();
    response = isUpdateCompany
      ? await companyApi.updateCompany({
          id: id!,
          name: companyManager.name,
          registrationNumber: parseInt(stringRegistrationNumber),
          subscriptionStartDate: companyManager.subscriptionStartDate,
          subscriptionEndDate: companyManager.subscriptionEndDate,
          yearsName: companyManager.yearsName,
        })
      : await companyApi.createCompany(companyManager);
    if (response.status === Httpstatus.SuccessOK) {
      store.dispatch(
        setEmitter({
          type: "success",
          message: isUpdateCompany
            ? lang.successUpdateCompany
            : lang.successAddCompany,
          position: "top-center",
        })
      );
      navigate("/companieslist");
    } else {
      store.dispatch(
        setEmitter({
          type: "error",
          message: isUpdateCompany
            ? lang.errorUpdateCompany
            : lang.errorCreateCompany,
          position: "top-center",
        })
      );
    }
    setIsLoading(false);
  };
  const handleStepPrevious = () => {
    step > 0
      ? setStep(step - 1)
      : store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
  };
  const handleStepFollowing = () => {
    step < 3 ? setStep(step + 1) : handleCreateUpdateCompany();
  };
  return (
    <ManageCompanyTemplate
      lang={lang}
      isUpdateCompany={isUpdateCompany}
      step={step}
      companyManager={companyManager}
      onClickPrevious={handleStepPrevious}
      onClickFollowing={handleStepFollowing}
      onChangeFirstNameRmq={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          rmq: { ...companyManager.rmq, firstName: e.target.value },
        })
      }
      onChangeLastNameRmq={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          rmq: { ...companyManager.rmq, lastName: e.target.value },
        })
      }
      onChangeMailRmq={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          rmq: { ...companyManager.rmq, mail: e.target.value },
        })
      }
      onChangePasswordRmq={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          rmq: { ...companyManager.rmq, password: e.target.value },
        })
      }
      onChangePhoneNumberRmq={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          rmq: { ...companyManager.rmq, phoneNumber: e.target.value },
        })
      }
      onChangeFirstNameManager={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          manager: { ...companyManager.manager, firstName: e.target.value },
        })
      }
      onChangeLastNameManager={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          manager: { ...companyManager.manager, lastName: e.target.value },
        })
      }
      onChangeMailManager={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          manager: { ...companyManager.manager, mail: e.target.value },
        })
      }
      onChangePasswordManager={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          manager: { ...companyManager.manager, password: e.target.value },
        })
      }
      onChangePhoneNumberManager={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          manager: { ...companyManager.manager, phoneNumber: e.target.value },
        })
      }
      onChangeCompanyName={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          name: e.target.value,
        })
      }
      onChangeCompanyRegistreNumber={(e: React.ChangeEvent<HTMLInputElement>) =>
        setCompanyManager({
          ...companyManager,
          registrationNumber: parseInt(e.target.value),
        })
      }
      onChangeCompanyYear={(e: string[]) =>
        setCompanyManager({
          ...companyManager,
          yearsName: e,
        })
      }
      onChangeDateStart={(e: any) => {
        setCompanyManager({ ...companyManager, subscriptionStartDate: e });
      }}
      onChangeDateEnd={(e: any) => {
        setCompanyManager({ ...companyManager, subscriptionEndDate: e });
      }}
      selectOptionYears={selectDataYears}
      isLoading={isLoading}
    />
  );
};
