import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import {
  IAddEpicToUsers,
  IArchiveDelete,
  ICreateUser,
  ISearch,
  IUpdateUser,
} from "../../Interfaces";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class UserAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/user");
  }
  async createUser(payload: ICreateUser): Promise<ApiResponse> {
    const response = await this._api.post(
      "create",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async addEpicIdToUsers(payload: IAddEpicToUsers): Promise<ApiResponse> {
    const response = await this._api.put(
      "addepicidtousers",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async getUsersIdsByEpicId(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getusersidsbyepicid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async updateUser(payload: IUpdateUser): Promise<ApiResponse> {
    const response = await this._api.put(
      "update",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async searchUsers(payload: ISearch): Promise<ApiResponse> {
    const url = `getallusersfiltre/?pageNumber=${payload.pageNumber}&perPage=${payload.perPage}&searchWord=${payload.searchWord}&isArchived=${payload.isArchived}`;
    return await this._api.get(url, handleHeaders(tokensEnum.accessToken));
  }
  async getUserById(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getuserbyid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async archiveUsers(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.put(
      `archiveusers`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
  async deleteUsers(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.delete(
      `deleteusers`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
}
export { UserAPI };
