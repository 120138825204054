import { ModalRefType } from "@piximind/ds-p-23";
import { ReactNode, Ref } from "react";

enum Elang {
  fr = "fr",
  en = "en",
}

interface IPageProps {
  children: JSX.Element | null | undefined | ReactNode;
}
interface ITypePayload {
  type?: string;
  payload?: string;
}
interface IKeys {
  [key: string]: string;
}

interface IKeysModal {
  [key: string]: Ref<ModalRefType | undefined | null>;
}
interface ITimerState {
  hours: number;
  minutes: number;
  seconds: number;
}
interface ISelectOption {
  label: string;
  value: string;
  isChecked?: boolean;
}
interface ISelectCheckBox {
  label: string;
  isChecked: boolean;
}
interface ICategory {
  name: string;
}
interface ILineAtomProps {
  isVertical?: boolean;
  className?: string;
  style?: object;
  bsPrefix?: string;
}
interface IDataStepperQuestion {
  title: string;
  text: string;
}
interface IArchivedList {
  isArchived: boolean;
}
interface ILoginForm {
  mail: string;
  password: string;
}
interface IAction {
  name: string;
  icon: string;
  onClick: Function;
}
export type {
  IPageProps,
  ITypePayload,
  IKeys,
  IKeysModal,
  ISelectOption,
  ILineAtomProps,
  ISelectCheckBox,
  IDataStepperQuestion,
  ITimerState,
  ICategory,
  IArchivedList,
  ILoginForm,
  IAction,
};

export { Elang };
