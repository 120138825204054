import { Button } from "@piximind/ds-p-23";
import { Size, Type } from "@piximind/ds-p-23/lib/esn/Interfaces";
import { IAction, IDropDownAtomProps } from "../../../Interfaces";

const DropDownAtom = (props: IDropDownAtomProps) => {
  return (
    <div className="ds-w-100 ds-flex ds-justify-end ds-pr-150">
      {props.showDropDown && (
        <div className="dropdown">
          {props.items?.map((item: IAction) => {
            return (
              <Button
                className=" dropdown-item ds-w-100 ds-bg-mds"
                icon={item.icon}
                size={Size.large}
                type={Type.primary}
                text={item.name}
                onClick={item.onClick}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDownAtom;
