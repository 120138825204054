import { PublicOrganism } from "../../Components";
import { IResetPasswordTemplate } from "../../Interfaces";
import { CommonValidation } from "../../Common";

function ResetPasswordTemplate({
  lang,
  mail,
  onClickButton,
  onChangeMail,
}: IResetPasswordTemplate) {
  return (
    <PublicOrganism
      isResetPassword
      lang={lang}
      mail={mail}
      onClickButtonAuth={onClickButton}
      isFormValid={CommonValidation.isMail(mail)}
      onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChangeMail && onChangeMail(e)
      }
      isValidMail={CommonValidation.isMail(mail)}
    />
  );
}
export default ResetPasswordTemplate;
