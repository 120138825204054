import { UserRoleTypes } from "../../Enum";

class Data {
  private static instance: Data;
  constructor() {}

  public static getInstance(): Data {
    if (!Data.instance) {
      Data.instance = new Data();
    }
    return Data.instance;
  }
  static getSidebarItems() {
    return [
      {
        isLeftSecondaryIcon: true,
        icon: "menu-alt-1",
        label: "liste des sociétés",
        path: "/",
      },
      {
        isLeftSecondaryIcon: true,
        icon: "plus-circle",
        label: "Ajouter société",
        path: "managecompany",
      },
      {
        isLeftSecondaryIcon: true,
        icon: "folder-open",
        label: "liste des utilisateurs",
        path: "userslist",
      },
      {
        isLeftSecondaryIcon: true,
        icon: "plus-circle",
        label: "Ajouter utilisateur",
        path: "managetest",
      },
      {
        isLeftSecondaryIcon: true,
        icon: "database",
        label: "archive des société",
        path: "candidateslist",
      },
      {
        isLeftSecondaryIcon: true,
        icon: "folder-open",
        label: "Archive des utilisateurs",
        path: "userslist",
      },
    ];
  }
  static getDataTableUsers() {
    return [
      { key: "user", label: "Utilisateur" },
      { key: "mail", label: "Adresse email" },
      { key: "role", label: "Role" },
      { key: "createdAt", label: "Date de création", isDate: true },
      { key: "action", label: "Actions" },
    ];
  }
  static getDataYears() {
    return [
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
      { label: "2026", value: "2026" },
      { label: "2027", value: "2027" },
      { label: "2028", value: "2028" },
      { label: "2029", value: "2029" },
      { label: "2030", value: "2030" },
      { label: "2031", value: "2031" },
      { label: "2032", value: "2032" },
      { label: "2033", value: "2033" },
      { label: "2034", value: "2034" },
      { label: "2035", value: "2035" },
      { label: "2036", value: "2036" },
      { label: "2037", value: "2037" },
      { label: "2038", value: "2038" },
      { label: "2039", value: "2039" },
      { label: "2040", value: "2040" },
    ];
  }
  static getDataTableCompanies() {
    return [
      { key: "name", label: "Nom de la société" },
      { key: "rmq", label: "Rmq" },
      { key: "manager", label: "Manager" },
      { key: "createdAt", label: "Date de céation", isDate: true },
      { key: "action", label: "Actions" },
    ];
  }
  static getDataTableEpics() {
    return [
      { key: "name", label: "Nom de processuss" },
      { key: "percentage", label: "Pourcentage" },
      { key: "createdAt", label: "Date de céation", isDate: true },
      { key: "action", label: "Actions" },
    ];
  }
  static getDataTableTasks() {
    return [
      { key: "name", label: "Nom de l'enregistrement" },
      { key: "percentage", label: "pourcentage" },
      { key: "createdAt", label: "Date de céation", isDate: true },
      { key: "action", label: "Actions" },
    ];
  }
  static getDataRoles() {
    return [
      { label: "User", value: UserRoleTypes.user },
      { label: "Rmq", value: UserRoleTypes.rmq },
      { label: "Manager", value: UserRoleTypes.manager },
      { label: "Admin", value: UserRoleTypes.admin },
    ];
  }
  static getDropdownItems(onClick: (index: number) => void) {
    return [
      { name: "lang.profile", icon: "user", onClick: () => onClick(0) },
      { name: "lang.logOut", icon: "logout", onClick: () => onClick(1) },
    ];
  }
}

export { Data };
