import { useEffect, useState } from "react";
import { CompanyAPI } from "../../Apis";
import { IArchivedList, ICompanyModel, ISearch } from "../../Interfaces";
import { IListData } from "@piximind/ds-p-23/lib/esn/Interfaces/Organisms/IOrganismTable/IOrganismTable";
import { CompaniesListTemplate } from "../../Templates";
import { store } from "../../Redux/Store/Store";
import { openModal, setEmitter } from "../../Redux/Reducers";
import { useNavigate } from "react-router";
import { Httpstatus } from "@piximind/api";
import { LangContext } from "../../Lang/Provider/Provider";
import React from "react";
export const CompaniesList = ({ isArchived }: IArchivedList) => {
  let counter: number = 1;
  const navigate = useNavigate();
  const companyApi = new CompanyAPI();
  const lang = React.useContext(LangContext);
  const [companies, setCompanies] = useState<IListData>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [search, setSearch] = useState<ISearch>({
    pageNumber: 1,
    perPage: 10,
    searchWord: "",
    isArchived: isArchived,
  });
  const [lisSelectElement, setListSelectElement] = useState<string[]>([]);
  useEffect(() => {
    getCompanies();
  }, [search.pageNumber, isArchived]);

  const getCompanies = async () => {
    try {
      const response = await companyApi.searchCompanies(search);
      if (response.status === 200) {
        const company = response.data.companies;
        const result = company.map((company: ICompanyModel) => {
          return {
            id: company?._id,
            name: company?.name,
            rmq: company?.rmqsId
              ?.map((rmq) => {
                return typeof rmq !== "string" ? rmq?.firstName : "";
              })
              .join(" , "),
            manager: company?.managersId
              ?.map((manager) => {
                return typeof manager !== "string" ? manager?.firstName : "";
              })
              .join(" , "),
            createdAt: company?.createdAt,
            createdBy:
              company?.createdBy?.firstName +
              " " +
              company?.createdBy?.lastName,
            _id: counter++,
          };
        });
        setCompanies(result);
        setNumberOfPages(response.data.numberOfPages);
      }
    } catch (err) {
      console.error("Internal Server err :", err);
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const deleteCompanies = async (list: Array<string>) => {
    try {
      const response = await companyApi.deleteCompanies({
        ids: list,
        isArchived: !isArchived,
      });
      getCompanies();
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successDeleteCompany,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const archiveCompanies = async (list: Array<string>) => {
    try {
      const response = await companyApi.archiveCompanies({
        ids: list,
        isArchived: !isArchived,
      });
      getCompanies();
      if (!isArchived && response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successArchiveCompany,
            position: "top-center",
          })
        );
        setListSelectElement([]);
      } else if (isArchived && response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successUnarchiveCompany,
            position: "top-center",
          })
        );
        setListSelectElement([]);
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  function openUrlsInNewWindows(ids: Array<string>) {
    ids.forEach((id) => {
      window.open(`managecompany/${id}`, "_blank");
    });
  }
  const handleSearchQueryChange = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (search.searchWord === "") {
        getCompanies();
      } else {
        setSearch({ ...search, pageNumber: 1 });
        getCompanies();
      }
    }
  };
  return (
    <CompaniesListTemplate
      lang={lang}
      onSelect={(list: string[]) => {
        setListSelectElement(list);
      }}
      onClickSelectEditIcon={() => {
        openUrlsInNewWindows(lisSelectElement);
      }}
      state={search.searchWord}
      onChange={(e: any) => {
        setSearch({ ...search, searchWord: e.target.value });
      }}
      onKeyPress={handleSearchQueryChange}
      elementData={companies}
      numberOfPage={numberOfPages}
      actionButtonModify={(e: any, i: any) => {
        navigate(`/managecompany/${companies[i].id}`);
      }}
      actionButtonArchive={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: !isArchived
                ? lang.modalTitleArchiveCompany
                : lang.modalTitleUnarchiveCompany,
              message: !isArchived
                ? lang.modalQuestionArchiveCompany
                : lang.modalQuestionUnarchiveCompany,
              submitText: !isArchived ? lang.archive : lang.unarchive,
              cancelText: lang.cancel,
              submit: async () =>
                await archiveCompanies([companies[i].id.toString()]),
            },
          })
        );
      }}
      actionButtonDelete={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: lang.modalTitleDeleteCompany,
              message: lang.modalQuestionDeleteCompany,
              submitText: lang.delete,
              cancelText: lang.cancel,
              submit: async () =>
                await deleteCompanies([companies[i].id.toString()]),
            },
          })
        );
      }}
      onPaginate={(e: number) => {
        setSearch({ ...search, pageNumber: e });
      }}
      pageNumber={search.pageNumber}
      listSelectedElement={lisSelectElement}
      isArchived={isArchived}
      actionButtonView={(e: any, i: any) => {
        navigate(`/epicslist/${companies[i]?.id}`);
      }}
      actionButtonViewArchive={(e: any, i: any) => {
        navigate(`/archiveepicslist/${companies[i]?.id}`);
      }}
      onClick={() => navigate("/managecompany")}
    />
  );
};
