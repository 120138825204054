import { tokensEnum } from "../../Enum"
import { store } from "../../Redux/Store/Store"

export function handleHeaders(tokenTypes: tokensEnum = tokensEnum.accessToken): Headers {
  const headers = new Headers({ "Content-Type": "application/json" })
  const { accessToken, refreshToken } = getAuthState()
  if (accessToken && tokenTypes === tokensEnum.accessToken) {
    setAuthorizationHeader(headers, accessToken)
  } else if (refreshToken && tokenTypes === tokensEnum.refreshToken) {
    setAuthorizationHeader(headers, refreshToken)
  }
  return headers
}

function getAuthState(): { accessToken: string; refreshToken: string } {
  const { accessToken, refreshToken } = store.getState().auth
  return { accessToken, refreshToken }
}

function setAuthorizationHeader(headers: Headers, token: string): void {
  headers.append("Authorization", `Bearer ${token}`)
}
