import { PublicOrganism } from "../../Components";
import { IChangePasswordTemplate } from "../../Interfaces";
import { CommonValidation } from "../../Common";

const ChangePasswordTemplate = ({
  lang,
  newPassword,
  onClickButton,
  onChangePassword,
}: IChangePasswordTemplate) => {
  return (
    <PublicOrganism
      isChangePassword
      lang={lang}
      password={newPassword}
      isValidPassword={CommonValidation.isValidPassword(newPassword)}
      onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChangePassword && onChangePassword(e)
      }
      onClickButtonAuth={onClickButton}
      isFormValid={CommonValidation.isValidPassword(newPassword)}
    />
  );
};
export default ChangePasswordTemplate;
