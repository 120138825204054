import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import { IRootState } from "../Interfaces/Redux";
import { ToastContainer } from "react-toastify";
import {
  ChangePassword,
  CompaniesList,
  EditTask,
  EpicsList,
  ManageCompany,
  ManageUser,
  NotFound,
  ResetPassword,
  SignIn,
  TasksList,
  UsersList,
} from "../Pages";
import { LangProvider, ModalProvider, ToastProvider } from "../Providers";

const AppRoute = (): JSX.Element => {
  const auth = useSelector((state: IRootState) => state?.auth);
  function PublicRouteRender(props: any) {
    return auth?.accessToken === null &&
      auth?.refreshToken === null &&
      auth.user === null ? (
      props.children
    ) : (
      <Navigate to="/" />
    );
  }
  function PrivateRouteRender(props: any) {
    return auth?.accessToken === null &&
      auth?.refreshToken === null &&
      auth?.user === null ? (
      <Navigate to="/login" />
    ) : (
      props?.children
    );
  }
  return (
    <LangProvider>
      <ToastContainer />
      <ToastProvider />
      <ModalProvider />
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <PublicRouteRender>
                <PublicRoute />
              </PublicRouteRender>
            }
          >
            <Route path="/login" element={<SignIn />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/changepassword/:token" element={<ChangePassword />} />
          </Route>

          <Route
            element={
              <PrivateRouteRender>
                <PrivateRoute />
              </PrivateRouteRender>
            }
          >
            <Route path="/" element={<CompaniesList isArchived={false} />} />
            <Route path="/managecompany/:id?" element={<ManageCompany />} />
            <Route
              path="/companieslist"
              element={<CompaniesList isArchived={false} />}
            />
            <Route
              path="/archivecompanieslist"
              element={<CompaniesList isArchived={true} />}
            />
            <Route path="/manageuser/:id?" element={<ManageUser />} />
            <Route
              path="/userslist"
              element={<UsersList isArchived={false} />}
            />
            <Route
              path="/archiveuserslist"
              element={<UsersList isArchived={true} />}
            />
            <Route
              path="/epicslist/:id?"
              element={<EpicsList isArchived={false} />}
            />
            <Route
              path="/archiveepicslist/:id?"
              element={<EpicsList isArchived={true} />}
            />
            <Route path="/taskslist/:id?/:idCompany?" element={<TasksList />} />
            <Route path="/editetask/:id?" element={<EditTask />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </LangProvider>
  );
};

export default AppRoute;
