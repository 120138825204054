import { PublicOrganism } from "../../Components";
import { ISignInTemplate } from "../../Interfaces";
import { CommonValidation } from "../../Common";

function SignInTemplate({
  lang,
  onClickButton,
  onChangeMail,
  onChangePassword,
  loginForm,
}: ISignInTemplate) {
  return (
    <PublicOrganism
      lang={lang}
      isSignIn
      mail={loginForm.mail}
      password={loginForm.password}
      isValidMail={CommonValidation.isMail(loginForm.mail)}
      onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChangeMail && onChangeMail(e)
      }
      isValidPassword={CommonValidation.isValidPassword(loginForm.password)}
      onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChangePassword && onChangePassword(e)
      }
      isFormValid={
        CommonValidation.isValidPassword(loginForm.password) &&
        CommonValidation.isMail(loginForm.mail)
      }
      onClickButtonAuth={onClickButton}
    />
  );
}
export default SignInTemplate;
