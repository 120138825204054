import { IKeys } from "../../Interfaces";

const lang: IKeys = {
  helloWorld: "Hello world",
  filtre: "Filtrage",
  page: "Page",
  modify: "Modifier",
  add: "Ajouter",
  year: "Année :",
  month: "Mois :",
  error: "Erreur !",
  archive: "Archiver",
  unarchive: "Désarchiver",
  delete: "Supprimer",
  cancel: "Annuler",
  percentage: "Pourcentage",
  successChangePassword: "Mot de passe changé avec succées !",
  changePassword: "Changer mot de passe",
  newPassword: "Nouveau mot de passe",
  returnLoginPage: "Retourn Login page",
  successDeleteCompany: "Entreprise supprimé avec succées !",
  successArchiveCompany: "Entreprise archivée avec succées !",
  successUnarchiveCompany: "Entreprise désarchivé avec succées",
  modalTitleArchiveCompany: "Archiver entreprise",
  modalTitleUnarchiveCompany: "Désarchiver entreprise",
  modalQuestionArchiveCompany:
    "Svp est ce que vous êtes sur d'archiver cette entreprise ?",
  modalQuestionUnarchiveCompany:
    "Svp est ce que vous êtes sur de désarchiver cette entreprise ?",
  modalTitleDeleteCompany: "Supprimer entreprise",
  modalQuestionDeleteCompany:
    "Svp est ce que vous êtes sur de supprimer cette entreprise ?",
  viewProcessus: "Voir les processus",
  viewArchivedProcessus: "Voir les processus archivées",
  successUpdateEnregistrement: "Enregistrement mise à jour avec succées",
  errorUpdateEnregistrement: "Erreur de mise à jour enregistrement",
  modifyEnregistrement: "Modifier enregistrement",
  enregistrementName: "Nom d'enregistrement",
  enterEnregistrementName: "Entrer le nom d'enregistrement",
  successAddProcessus: "Processus ajouté avec succées !",
  successDeleteProcessus: "Processus supprimé avec succées !",
  successUnarchiveProcessus: "Enregistrement désarchivé avec succées !",
  successArchiveProcessus: "Enregistrement archivé avec succées !",
  archivedProcessusListe: "Liste des processus archivées",
  processusListe: "Liste des processus",
  processusName: "Nom de processus :",
  successUpdateCompany: "Entreprise mise à jour avec succees !",
  successAddCompany: "Société crée avec succées !",
  errorUpdateCompany: "Erreur de mise à jour d'entreprise !",
  errorCreateCompany: "Erreur de la création de l'entreprise !",
  modifyCompany: "Modifier entreprise",
  addRmq: "Ajouter rmq",
  addManager: "Ajouter manager",
  addCompanyInformation: "Ajouter information d'entreprise",
  modifyUser: "Modifier utilisateur",
  addUser: "Ajouter utilisateur",
  name: "Nom :",
  prename: "Prénom :",
  mailAdress: "Adresse mail :",
  password: "Mot de passe :",
  phoneNumber: "Numéro de téléphone :",
  role: "Role :",
  company: "Entreprise :",
  createUser: "Crée utilisateur",
  updateUser: "Mise à jour utilisateur",
  successAddUser: "Utilisateur ajouté avec succées !",
  successUpdateUser: "Mise à jour utilisateur avec succées !",
  errorUpdateUser: "Erreur de mise à jour utilisateur !",
  errorCreateUser: "Erreur de création d'utilisateur !",
  errorPageNotFound: "Erreur 404 : Page n'existe pas !",
  successSendMail: "Mail envoyer avec succées",
  verifMailAdress: "Erreur svp verifier l'adresse mail !",
  sendMailChangePassword: "Envoyer mail changer mot de passe",
  sendMail: "Envoyer mail",
  loginAdminMds: "Login Admin Mds",
  forgotPassword: "Forget password ?",
  login: "Login",
  successDeleteEnregistrement: "enregistrement supprimé avec succées",
  successAddEnregistrement: "Enregistrement ajouté avec succées",
  modifyProcessusAndEnregistrementSucces:
    "Modifier processus && Liste des enregistrements",
  addNewEnregistrement: "Ajouter nouvelle enregistrement",
  archiveUsersListe: "Liste des utilisateurs archivées",
  usersListe: "Liste des utilisateurs",
  successDeleteUsers: "utilisateur(s) supprimer avec succées",
  successArchiveUsers: "Utilisateur(s) archivé avec succées",
  successUnarchiveUsers: "Utilisateur(s) désarchivé avec succées",
  modalArchiveUserTitle: "Archiver utilisateur",
  modalUnarchiveUserTitle: "Désarchiver utilisateur",
  modalMessageArchiveUser:
    "Est ce que vous êtes sur d'archiver cette utilisateur(s) ?",
  modalMessageUnarchiveUser:
    "Est ce que vous êtes sur de désarchiver cette utilisateur(s) ?",
  modalDeleteUserTitle: "Supprimer utilisateur",
  modalDeleteUserText:
    "Est ce que vous êtes sur de supprimer cette utilisateur(s) ?",
  comment: "Commentaire",
  assignedTo: "Assigné cette processus à",
  addNewEpic: "Ajouter nouvelle enregistrement",
  confirm: "Confirmer",
  successAssigneTo: "processus assigné avec succées !",
  companyName: "Nom d'entreprise :",
  previous: "précédent",
  next: "suivant",
  submit:"Soumettre",
  logout: "Déconnecter",
  mdsConsulting:"MDS-Consulting",
  archivedCompaniesList: "Liste des entrepises archivées",
  companiesList: "Liste des entrepises",
  registreNumber: "Matricule fiscale :",
  contratStartDate:"Date début contrat :",
  contratEndDate:"Date fin contrat :" 
};
export default lang;
