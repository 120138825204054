import { useEffect, useState } from "react";
import { UserAPI } from "../../Apis";
import { IArchivedList, ISearch, IUserModel } from "../../Interfaces";
import { IListData } from "@piximind/ds-p-23/lib/esn/Interfaces/Organisms/IOrganismTable/IOrganismTable";
import { UsersListTemplate } from "../../Templates";
import { openModal, setEmitter } from "../../Redux/Reducers";
import { store } from "../../Redux/Store/Store";
import { Httpstatus } from "@piximind/api";
import { useNavigate } from "react-router";
import React from "react";
import { LangContext } from "../../Lang/Provider/Provider";
export const UsersList = ({ isArchived }: IArchivedList) => {
  const lang = React.useContext(LangContext);
  let counter = 1;
  const navigate = useNavigate();
  const userApi = new UserAPI();
  const [users, setUsers] = useState<IListData>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [search, setSearch] = useState<ISearch>({
    pageNumber: 1,
    perPage: 10,
    searchWord: "",
    isArchived: isArchived,
  });
  const [lisSelectElement, setListSelectElement] = useState<string[]>([]);
  useEffect(() => {
    getUsers();
  }, [search.pageNumber, isArchived]);
  const getUsers = async () => {
    const response = await userApi.searchUsers(search);
    if (response.status === 200) {
      const users = response.data.users;
      const result = users.map((user: IUserModel) => {
        return {
          id: user?._id,
          user: user?.firstName + " " + user?.lastName,
          mail: user?.mail,
          role: user?.role,
          createdAt: user?.createdAt,
          createdBy:
            user?.createdBy?.firstName + " " + user?.createdBy?.lastName,
          _id: counter++,
        };
      });
      setUsers(result);
      setNumberOfPages(response.data.numberOfPages);
    }
  };
  const deleteUsers = async (list: Array<string>) => {
    try {
      const response = await userApi.deleteUsers({
        ids: list,
        isArchived: !isArchived,
      });
      getUsers();
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successDeleteUsers,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const archiveUsers = async (list: Array<string>) => {
    try {
      const response = await userApi.archiveUsers({
        ids: list,
        isArchived: !isArchived,
      });
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: !isArchived
              ? lang.successArchiveUsers
              : lang.successUnarchiveUsers,
            position: "top-center",
          })
        );
        setListSelectElement([]);
        getUsers();
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  function openUrlsInNewWindows(ids: Array<string>) {
    ids.forEach((id) => {
      window.open(`manageuser/${id}`, "_blank");
    });
  }
  const handleSearchQueryChange = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (search.searchWord === "") {
        getUsers();
      } else {
        setSearch({ ...search, pageNumber: 1 });
        getUsers();
      }
    }
  };
  return (
    <UsersListTemplate
      lang={lang}
      onSelect={(list: string[]) => {
        setListSelectElement(list);
      }}
      onClickSelectEditIcon={() => {
        openUrlsInNewWindows(lisSelectElement);
      }}
      state={search.searchWord}
      onChange={(e: any) => {
        setSearch({ ...search, searchWord: e.target.value });
      }}
      onKeyPress={handleSearchQueryChange}
      elementData={users}
      numberOfPage={numberOfPages}
      actionButtonModify={(e: any, i: any) => {
        navigate(`/manageuser/${users[i].id}`);
      }}
      actionButtonArchive={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: !isArchived
                ? lang.modalArchiveUserTitle
                : lang.modalUnarchiveUserTitle,
              message: !isArchived
                ? lang.modalMessageArchiveUser
                : lang.modalMessageUnarchiveUser,
              submitText: !isArchived ? lang.archive : lang.unarchive,
              cancelText: lang.cancel,
              submit: async () => await archiveUsers([users[i].id.toString()]),
            },
          })
        );
      }}
      actionButtonDelete={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: lang.modalDeleteUserTitle,
              message:
                lang.modalDeleteUserText,
              submitText: lang.delete,
              cancelText: lang.cancel,
              submit: async () => await deleteUsers([users[i].id.toString()]),
            },
          })
        );
      }}
      onPaginate={(e: number) => {
        setSearch({ ...search, pageNumber: e });
      }}
      pageNumber={search.pageNumber}
      listSelectedElement={lisSelectElement}
      isArchived={isArchived}
      onClick={() => navigate("/manageuser")}
    />
  );
};
