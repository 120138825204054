import { Button, ELang, Input, Table, Text } from "@piximind/ds-p-23";
import { IListEpicsTemplate } from "../../Interfaces";
import { Data } from "../../Common";
import { SearchBarOrganism } from "../../Components/Organisms/SearchBar/SearchBar";
import { TitlePageMolecule } from "../../Components";

function EpicsListTemplate({
  lang,
  isArchived,
  state,
  newEpicValue,
  onKeyPress,
  onPaginate,
  onChange,
  onChangeEpicValue,
  onClick,
  onClickAddEpic,
  elementData,
  numberOfPage,
  actionButtonModify,
  actionButtonArchive,
  actionButtonDelete,
  pageNumber,
  listSelectedElement,
  onSelect,
  onClickSelectEditIcon,
  onClickSelectArchiveIcon,
  onClickSelectDeleteIcon,
  selectOptionsYears,
  selectOptionsSessions,
  onChangeSessionId,
  onChangeYearId,
  selectedSessionId,
  selectedYearId,
  session,
}: IListEpicsTemplate) {
  return (
    <div className="ds-pl-25 ds-pt-28 ds-w-100">
      <TitlePageMolecule
        title={lang.page}
        subTitle={
          isArchived ? lang.archivedProcessusListe : lang.processusListe
        }
      />
      <div className="ds-flex border-solid ds-p-10 ds-mr-10">
        <Input
          label={lang.year}
          isSelect
          selectOption={selectOptionsYears}
          onChangeSelect={(e: string[]) => {
            onChangeYearId(e);
          }}
          selectValue={[selectedYearId]}
        />
        <div className="ds-w-5" />
        <Input
          label={lang.month}
          isSelect
          selectOption={selectOptionsSessions}
          onChangeSelect={(e: string[]) => {
            onChangeSessionId(e);
          }}
          selectValue={[selectedSessionId]}
        />
        {!isArchived && (
          <>
            <div className="ds-w-5" />
            <Input
              className="ds-ml-25"
              label={lang.processusName}
              value={newEpicValue}
              onChange={onChangeEpicValue}
              placeholder={"Ajouter nouvelle processus"}
            />
            <Button
              className="ds-mt-23 ds-ml-10"
              text={lang.add}
              icon="plus"
              onClick={onClickAddEpic}
              disabled={newEpicValue === ""}
            />
          </>
        )}
        <div className="ds-w-5" />
      </div>
      <div className="ds-justify-between ds-flex ds-w-100 ds-pt-15">
        <SearchBarOrganism
          lang={lang}
          isEdit
          isArchived={isArchived}
          state={state}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onClickSelectEditIcon={onClickSelectEditIcon}
          onClickSelectArchiveIcon={onClickSelectArchiveIcon}
          onClickSelectDeleteIcon={onClickSelectDeleteIcon}
          listSelectedElement={listSelectedElement}
        />
      </div>
      <div className="border-solid ds-p-15 ds-mt-25 ds-mr-10">
        {session?.nameFr && (
          <Text
            className="ds-mt-10"
            size={24}
            text={`${lang.percentage} de ${session?.nameFr} :   ${
              (session.percentage ?? 0) + " %"
            } `}
          />
        )}
        <Table
          className="ds-mt-27 ds-fs-mds"
          headers={Data.getDataTableEpics()}
          data={elementData}
          withPagination
          withCheckox
          listSelectedElement={listSelectedElement}
          onSelect={onSelect}
          isRightPagination
          numberOfPage={numberOfPage}
          actions={[
            {
              label: lang.modify,
              icon: "pencil",
              onClick: actionButtonModify,
              isDisplayed: true,
            },
            {
              label: lang.archive,
              icon: isArchived ? "folder-open" : "server",
              onClick: actionButtonArchive,
              isDisplayed: true,
            },
            {
              label: lang.delete,
              icon: "trash",
              onClick: actionButtonDelete,
              isDisplayed: true,
            },
          ]}
          onPaginate={onPaginate}
          page={pageNumber}
          lang={ELang.fr}
        />
      </div>
    </div>
  );
}
export default EpicsListTemplate;
