import { Provider } from "react-redux";
import AppRoute from "./Routes/AppRoute";
import { persistor, store } from "./Redux/Store/Store";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { Helmet } from "react-helmet";

const App = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MDS Consulting</title>
      </Helmet>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoute />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
