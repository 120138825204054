import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IArchivedList, ISearch, ISessionModel } from "../../Interfaces";
import { CompanyAPI, EpicAPI, YearAPI } from "../../Apis";
import EpicsListTemplate from "../../Templates/EpicsList/EpicsList";
import { IListData } from "@piximind/ds-p-23/lib/esn/Interfaces/Organisms/IOrganismTable/IOrganismTable";
import { store } from "../../Redux/Store/Store";
import { openModal, setEmitter } from "../../Redux/Reducers";
import { Httpstatus } from "@piximind/api";
import React from "react";
import { LangContext } from "../../Lang/Provider/Provider";

export const EpicsList = ({ isArchived }: IArchivedList) => {
  let counter: number = 1;
  const lang = React.useContext(LangContext);
  const navigate = useNavigate();
  const epicApi = new EpicAPI();
  const companyApi = new CompanyAPI();
  const yearApi = new YearAPI();
  const { id } = useParams<{ id: string }>();
  const [epics, setEpics] = useState<IListData>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [session, setSession] = useState<ISessionModel>();
  const [lisSelectElement, setListSelectElement] = useState<string[]>([]);
  const [selectedSessionId, setSelectedSessionId] = useState<string>("");
  const [newEpicValue, setNewEpicValue] = useState<string>("");
  const [selectedYearId, setSelectedYearId] = useState<string>("");
  const [selectOptionsYears, setSelectOptionsYears] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [selectOptionsSessions, setSelectOptionsSessions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [search, setSearch] = useState<ISearch>({
    pageNumber: 1,
    perPage: 10,
    searchWord: "",
    isArchived: isArchived,
  });
  useEffect(() => {
    getYearsId();
  }, [selectOptionsSessions]);
  useEffect(() => {
    getSessionsId();
  }, [selectedYearId]);
  useEffect(() => {
    getEpics();
  }, [selectedSessionId]);
  useEffect(() => {
    getEpics();
  }, [search.pageNumber]);
  const getSessionsId = async () => {
    const response = await yearApi.getYearById(selectedYearId);
    if (response.status === 200) {
      const sessionsId = response.data?.year?.sessionsId;
      const result = sessionsId.map((session: any) => {
        {
          return {
            value: session?._id,
            label: session?.nameFr,
          };
        }
      });
      setSelectOptionsSessions(result);
    }
  };
  const getYearsId = async () => {
    const response = await companyApi.getCompanyById(id!);
    if (response.status === 200) {
      const yearsId = response.data?.company?.yearsId;
      const result = yearsId.map((year: any) => {
        {
          return {
            value: year?._id,
            label: year?.name,
          };
        }
      });
      setSelectOptionsYears(result);
    } else {
      navigate("/");
    }
  };
  const getEpics = async () => {
    const response = await epicApi.searchEpics(search);
    if (response.status === 200) {
      const epics = response.data.epics;
      setSession(epics[0]?.sessionId);
      const result = epics.map((epic: any) => {
        return {
          id: epic?._id,
          name: epic?.name,
          percentage: epic?.percentage + "%",
          createdAt: epic?.createdAt,
          createdBy:
            epics?.createdBy?.firstName + " " + epics?.createdBy?.lastName,
          _id: counter++,
        };
      });
      setEpics(result);
      setNumberOfPages(response.data.numberOfPages);
    }
  };
  const archiveEpics = async (list: Array<string>) => {
    try {
      const response = await epicApi.archiveEpics({
        ids: list,
        isArchived: !isArchived,
      });
      getEpics();
      if (!isArchived && response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successArchiveProcessus,
            position: "top-center",
          })
        );
        setListSelectElement([]);
      } else if (isArchived && response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successUnarchiveProcessus,
            position: "top-center",
          })
        );
        setListSelectElement([]);
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const deleteEpics = async (list: Array<string>) => {
    try {
      const response = await epicApi.deleteEpics({
        ids: list,
        isArchived: !isArchived,
      });
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successDeleteProcessus,
            position: "top-center",
          })
        );
        getEpics();
        setListSelectElement([]);
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  function openUrlsInNewWindows(ids: Array<string>) {
    ids.forEach((id) => {
      window.open(`taskslist/${id}`, "_blank");
    });
  }
  const handleSearchQueryChange = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (search.searchWord === "") {
        getEpics();
      } else {
        setSearch({ ...search, pageNumber: 1 });
        getEpics();
      }
    }
  };
  const handleAddEpic = async () => {
    try {
      if (selectedSessionId !== "") {
        const response = await epicApi.createEpic({
          name: newEpicValue,
          sessionId: selectedSessionId,
          tasksName: [],
        });
        if (
          response.status === Httpstatus.SuccessOK ||
          response.status === Httpstatus.SuccessCreated
        ) {
          setNewEpicValue("");
          getEpics();
          store.dispatch(
            setEmitter({
              type: "success",
              message: lang.successAddProcessus,
              position: "top-center",
            })
          );
        } else {
          store.dispatch(
            setEmitter({
              type: "error",
              message: lang.error,
              position: "top-center",
            })
          );
        }
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  return (
    <EpicsListTemplate
      lang={lang}
      onClick={handleAddEpic}
      onSelect={(list: string[]) => {
        setListSelectElement(list);
      }}
      onClickSelectEditIcon={() => {
        openUrlsInNewWindows(lisSelectElement);
      }}
      state={search.searchWord}
      onChange={(e: any) => {
        setSearch({ ...search, searchWord: e.target.value });
      }}
      onKeyPress={handleSearchQueryChange}
      elementData={epics}
      numberOfPage={numberOfPages}
      actionButtonModify={(e: any, i: any) => {
        navigate(`/taskslist/${epics[i].id}/${id}`);
      }}
      actionButtonArchive={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: !isArchived
                ? "Archiver processus"
                : "Désarchiver processus",
              message: !isArchived
                ? "Etes vous sur d'archiver processus ?"
                : "Etes vous sur de désarchiver processus ?",
              submitText: !isArchived ? "Archiver" : "Désarchiver",
              cancelText: "Annuler",
              submit: async () => await archiveEpics([epics[i].id.toString()]),
            },
          })
        );
      }}
      actionButtonDelete={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: "Supprimer processus",
              message: "Etes vous sur de supprimer cette processus ?",
              submitText: "Supprimer",
              cancelText: "Annuler",
              submit: async () => await deleteEpics([epics[i].id.toString()]),
            },
          })
        );
      }}
      onPaginate={(e: number) => {
        setSearch({ ...search, pageNumber: e });
      }}
      pageNumber={search.pageNumber}
      listSelectedElement={lisSelectElement}
      isArchived={false}
      newEpicValue={newEpicValue}
      onClickAddEpic={handleAddEpic}
      selectOptionsYears={selectOptionsYears}
      selectOptionsSessions={selectOptionsSessions}
      onChangeYearId={(e: string[]) => {
        setSelectedYearId(e[0]);
      }}
      onChangeSessionId={(e: string[]) => {
        setSelectedSessionId(e[0]);
        setSearch({ ...search, id: e[0] });
      }}
      selectedYearId={selectedYearId}
      selectedSessionId={selectedSessionId}
      onChangeEpicValue={(e: any) => {
        setNewEpicValue(e.target.value);
      }}
      session={session!}
    />
  );
};
