import { Button, ELang, Input, Table, Text } from "@piximind/ds-p-23";
import { IListTasksTemplate } from "../../Interfaces";
import { Data } from "../../Common";
import { SearchBarOrganism } from "../../Components/Organisms/SearchBar/SearchBar";
import { TitlePageMolecule } from "../../Components";

function TasksListTemplate({
  lang,
  isArchived,
  state,
  newTaskValue,
  onKeyPress,
  onPaginate,
  onChange,
  onChangeTaskValue,
  onClick,
  onClickAddTask,
  elementData,
  numberOfPage,
  actionButtonModify,
  actionButtonDelete,
  pageNumber,
  listSelectedElement,
  onSelect,
  onClickSelectEditIcon,
  onClickSelectArchiveIcon,
  onClickSelectDeleteIcon,
  epicValue,
  onChangeEpicValue,
  onClickEpicEdit,
  epicPercentage,
  selectOptionUsers,
  selectedOptionsValue,
  onChangeSelectedOptionsValue,
  onClickConfirmAssignTo,
}: IListTasksTemplate) {
  return (
    <div className="ds-pl-25 ds-pt-28 ds-w-100">
      <TitlePageMolecule
        title={lang.page}
        subTitle={lang.modifyProcessusAndEnregistrementSucces}
      />
      <div className="ds-mr-7">
        <div className="ds-flex ds-mb-5 ds-w-100 border-solid ds-p-10">
          <Input
            className="ds-ml-25"
            label={lang.processusName}
            value={epicValue}
            onChange={onChangeEpicValue}
            placeholder={lang.addNewEpic}
          />
          <Button
            className="ds-mt-23 ds-ml-10"
            text={lang.modify}
            icon="pencil"
            onClick={onClickEpicEdit}
            disabled={epicValue === ""}
          />
          <div className="ds-w-2" />
        </div>
        <div className="ds-flex ds-mb-5 ds-w-100 border-solid ds-p-10">
          <Input
            className="ds-ml-25 ds-mr-5"
            isSelect
            label={lang.assignedTo}
            selectValue={selectedOptionsValue}
            selectOption={selectOptionUsers}
            isMulti
            onChangeSelect={(e: string[]) => {
              onChangeSelectedOptionsValue && onChangeSelectedOptionsValue(e);
            }}
          />
          <Button
            className="ds-mt-23 ds-ml-10"
            text={lang.confirm}
            icon="pencil"
            onClick={onClickConfirmAssignTo}
            disabled={epicValue === ""}
          />
          <div className="ds-w-2" />
        </div>
        <div className="ds-flex ds-w-100 border-solid ds-p-10">
          <Input
            className="ds-ml-25"
            label={lang.enregistrementName}
            value={newTaskValue}
            onChange={onChangeTaskValue}
            placeholder={lang.addNewEnregistrement}
          />
          <Button
            className="ds-mt-23 ds-ml-10"
            text={lang.add}
            icon="plus"
            onClick={onClickAddTask}
            disabled={newTaskValue === ""}
          />
          <div className="ds-w-2" />
        </div>
      </div>
      <div className="ds-justify-between ds-flex ds-w-100 ds-pt-15">
        <SearchBarOrganism
          lang={lang}
          isArchived={isArchived}
          state={state}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onClickSelectEditIcon={onClickSelectEditIcon}
          onClickSelectArchiveIcon={onClickSelectArchiveIcon}
          onClickSelectDeleteIcon={onClickSelectDeleteIcon}
          listSelectedElement={listSelectedElement}
        />
      </div>
      <div className="border-solid ds-p-15 ds-mt-25 ds-mr-10">
        <Text
          className="ds-mt-10"
          size={24}
          text={`${lang.percentage} de processus :   ${epicPercentage + " %"} `}
        />
        <Table
          className="ds-mt-27 ds-fs-mds"
          headers={Data.getDataTableTasks()}
          data={elementData}
          withPagination
          withCheckox
          listSelectedElement={listSelectedElement}
          onSelect={onSelect}
          isRightPagination
          numberOfPage={numberOfPage}
          actions={[
            {
              label: lang.modify,
              icon: "pencil",
              onClick: actionButtonModify,
              isDisplayed: true,
            },
            {
              label: lang.delete,
              icon: "trash",
              onClick: actionButtonDelete,
              isDisplayed: true,
            },
          ]}
          onPaginate={onPaginate}
          page={pageNumber}
          lang={ELang.fr}
        />
      </div>
    </div>
  );
}
export default TasksListTemplate;
