import { Modal } from "../../DesignSystem"
import { IGenericModal } from "../../Interfaces/Modal"
import { Ref, forwardRef, useState } from "react"
import { ModalRefType } from "../../DesignSystem/Interfaces/Interfaces"
import { Size, Type } from "@piximind/ds-p-23/lib/esn/Interfaces"
import GenericModalOrganism from "../GenericModalOrganism/GenericModalOrganism"

const GenericModal = (props: IGenericModal, ref: Ref<ModalRefType | undefined>) => {
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const onSubmit = () => {
    if (props.data.cancel) {
      props.data.cancel()
    }
    if (props.onExit) {
      props.onExit()
    }
  }

  const onReset = async () => {
    setIsLoadingBtn(true)
    if (props.data.submit) {
      await props.data.submit()
    }
    if (props.onExit) {
      props.onExit()
    }
    setIsLoadingBtn(false)
  }

  const btnCancelProps = {
    onClick: onSubmit,
    type: Type.secondary,
    size: Size.large,
    text: props.data?.cancelText
  }

  const btnSubmitProps = {
    onClick: onReset,
    size: Size.large,
    text: props.data?.submitText,
    isLoading: isLoadingBtn,
    disabled: isLoadingBtn
  }

  return (
    <Modal
      containerBtnClassName="ds-text-weight700 ds-text-size-18 ds-text-line-28"
      contentClassName="modal-content"
      onShow={props.onShow}
      withCloseAction
      ref={ref}
      withCloseIcon
      withSubmitAction
      onExit={props.onExit}
      btnResetProps={btnCancelProps}
      btnSubmitProps={btnSubmitProps}
    >
      <GenericModalOrganism title={props.data?.title} message={props.data?.message} />
    </Modal>
  )
}
export default forwardRef(GenericModal)
