import { Input, Button, TextArea } from "@piximind/ds-p-23";
import { IEditTaskTemplate } from "../../Interfaces";
import { TitlePageMolecule } from "../../Components";

function EditTaskTemplate({
  lang,
  task,
  onChange,
  onClickEditTask,
  taskValue,
  comment,
  onChangeComment,
}: IEditTaskTemplate) {
  return (
    <div className="ds-w-100">
      <div className="ds-m-30 ds-p-20">
        <TitlePageMolecule
          title={lang.page}
          subTitle={lang.modifyEnregistrement}
        />
        <div className="border-solid ds-p-25">
          <Input
            label={lang.enregistrementName}
            placeholder={lang.enterEnregistrementName}
            value={taskValue}
            onChange={(e: any) => onChange(e)}
            isValid={taskValue !== ""}
            isInvalid={taskValue === ""}
          />
          <Input
            className="ds-mt-30"
            disabled
            label={lang.percentage}
            value={`${task?.state} %`}
            isValid
          />
          <TextArea
            className="ds-mt-30"
            label={lang.comment}
            value={comment}
            isValid={comment !== undefined && comment !== ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeComment && onChangeComment(e);
            }}
          />
        </div>
        <div className="ds-flex ds-justify-end ds-mt-30">
          <Button icon="pencil" text={lang.modify} onClick={onClickEditTask} />
        </div>
      </div>
    </div>
  );
}
export default EditTaskTemplate;
