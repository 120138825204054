import { Button, Input, SizeButton, TypeButton } from "@piximind/ds-p-23";
import { ISearchBarOrganism } from "../../../Interfaces";

const SearchBarOrganism = ({
  lang,
  isArchived,
  state,
  onChange,
  onClick,
  onKeyPress,
  onClickSelectEditIcon,
  onClickSelectArchiveIcon,
  onClickSelectDeleteIcon,
  isAddButton,
  listSelectedElement,
  isEdit,
}: ISearchBarOrganism): JSX.Element => {
  return (
    <div className="ds-justify-between border-solid ds-p-10 ds-flex ds-w-100 ds-mr-7">
      <Input
        className="ds-ml-25"
        value={state}
        listIcons={[{ icon: "search" }]}
        onChange={onChange}
        placeholder={lang.filtre}
        onKeyPress={onKeyPress}
      />
      {!isAddButton && <div className="ds-w-2" />}
      {isAddButton && (
        <Button
          className="ds-ml-10 ds-bg-mds ds-mr-10"
          text={lang.add}
          icon="plus"
          type={TypeButton.primary}
          size={SizeButton.medium}
          onClick={onClick}
        />
      )}
    </div>
  );
};
export { SearchBarOrganism };
