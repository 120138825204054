import { Button, Input, SizeButton, TypeButton } from "@piximind/ds-p-23";
import { IManageUserTemplate } from "../../Interfaces";
import { TitlePageMolecule } from "../../Components";
import { CommonValidation } from "../../Common";
import { UserRoleTypes } from "../../Enum";

const ManageUserTemplate = ({
  lang,
  isUpdateUser,
  isUseInAnotherTemplate,
  userManager,
  onChangeFirstName,
  onChangeLastName,
  onChangeMail,
  onChangePassword,
  onChangePhoneNumber,
  selectOptionRole,
  onClickButton,
  onChangeRole,
  selectOptionCompany,
  onChangeCompany,
  selectedCompany,
  isLoading
}: IManageUserTemplate) => {
  return (
    <div className={!isUseInAnotherTemplate ? " ds-p-30" : ""}>
      {!isUseInAnotherTemplate && (
        <TitlePageMolecule
          title={lang.page}
          subTitle={isUpdateUser ? lang.modifyUser : lang.addUser}
        />
      )}
      <div
        className={`ds-w-100 ${
          !isUseInAnotherTemplate ? `ds-mt-20 border-solid ds-p-20` : ``
        }`}
      >
        <div className="ds-flex ds-w-100 ds-m-10">
          <Input
            className="ds-mr-10"
            label={lang.name}
            value={userManager.firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFirstName && onChangeFirstName(e)
            }
            isValid={userManager.firstName !== ""}
            isInvalid={userManager.firstName === ""}
          />
          <div className="ds-w-5" />
          <Input
            label={lang.prename}
            value={userManager.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeLastName && onChangeLastName(e)
            }
            isValid={userManager.lastName !== ""}
            isInvalid={userManager.lastName === ""}
          />
        </div>
        <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
          <Input
            className="ds-mr-10"
            label={lang.mailAdress}
            value={userManager.mail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeMail && onChangeMail(e)
            }
            isValid={CommonValidation.isMail(userManager.mail)}
            isInvalid={!CommonValidation.isMail(userManager.mail)}
          />
          {!isUpdateUser && (
            <>
              <div className="ds-w-5" />
              <Input
                label={lang.password}
                className="ds-ml-10"
                isPassword
                disabled={isUpdateUser}
                value={userManager.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangePassword && onChangePassword(e)
                }
                isValid={CommonValidation.isValidPassword(userManager.password)}
                isInvalid={
                  !CommonValidation.isValidPassword(userManager.password)
                }
              />
            </>
          )}
        </div>
        <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
          <Input
            className="ds-flex ds-mr-20"
            label={lang.phoneNumber}
            value={userManager.phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePhoneNumber && onChangePhoneNumber(e)
            }
            isValid={CommonValidation.isPhoneNumber(userManager.phoneNumber)}
            isInvalid={!CommonValidation.isPhoneNumber(userManager.phoneNumber)}
          />

          {!isUseInAnotherTemplate && (
            <>
              <div className="ds-w-5" />
              <Input
                label={lang.role}
                isSelect
                selectOption={selectOptionRole}
                selectValue={[userManager.role!]}
                onChangeSelect={(e: string[]) => {
                  onChangeRole && onChangeRole(e);
                }}
                isValid={userManager.role !== ""}
                isInvalid={userManager.role === ""}
              />
            </>
          )}
        </div>
        <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
          {!isUseInAnotherTemplate &&
            (userManager.role !== UserRoleTypes.admin || "") && (
              <Input
                label={lang.company}
                isSelect
                selectOption={selectOptionCompany}
                selectValue={[selectedCompany ?? ""]}
                onChangeSelect={(e: string[]) => {
                  onChangeCompany && onChangeCompany(e);
                }}
                isValid={selectedCompany !== ""}
                isInvalid={selectedCompany === ""}
              />
            )}
        </div>
      </div>
      {!isUseInAnotherTemplate && (
        <div className="ds-flex ds-justify-end ds-w-100 ds-mt-20">
          <Button
            text={!isUpdateUser ? lang.createUser : lang.updateUser}
            type={TypeButton.primary}
            size={SizeButton.medium}
            onClick={onClickButton}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};
export default ManageUserTemplate;
