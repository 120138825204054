import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import {
  IArchiveDelete,
  ICreateEpic,
  ISearch,
  IUpdateEpic,
} from "../../Interfaces";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class EpicAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/epic");
  }
  async searchEpics(payload: ISearch): Promise<ApiResponse> {
    const url = `getallepicsfiltre/?pageNumber=${payload.pageNumber}&perPage=${payload.perPage}&searchWord=${payload.searchWord}&isArchived=${payload.isArchived}&id=${payload.id}`;
    return await this._api.get(url, handleHeaders(tokensEnum.accessToken));
  }
  async createEpic(payload: ICreateEpic): Promise<ApiResponse> {
    const response = await this._api.post(
      "create",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async updateEpic(payload: IUpdateEpic): Promise<ApiResponse> {
    const response = await this._api.put(
      "update",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async getEpicById(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getepicbyid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async archiveEpics(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.put(
      `archiveepics`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
  async deleteEpics(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.delete(
      `deleteepics`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
}
export { EpicAPI };
