import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import { IChangePassword, IResetPassword, ISignIn } from "../../Interfaces";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class AuthAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/auth");
  }

  async login(payload: ISignIn): Promise<ApiResponse> {
    const response = await this._api.post(
      "login",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.noToken)
    );
    return response;
  }
  async resetPassword(payload: IResetPassword): Promise<ApiResponse> {
    const response = await this._api.post(
      "resetpassword",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.noToken)
    );
    return response;
  }
  async changePassword(
    token: string,
    payload: IChangePassword
  ): Promise<ApiResponse> {
    const response = await this._api.post(
      `changepassword/${token}`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.noToken)
    );
    return response;
  }
}
export { AuthAPI };
