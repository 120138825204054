import { Elang, IPageProps } from "../../Interfaces";
import { LangContext } from "../../Lang/Provider/Provider";
import { useSelector } from "react-redux";
import EN from "../../Lang/En/en";
import FR from "../../Lang/Fr/fr";
import { IRootState } from "../../Interfaces/Redux";
export default function LangProvider(props: IPageProps): JSX.Element {
  const lang = useSelector((state: IRootState) => state.setting.lang);
  let type = FR;
  if (lang === Elang.en) {
    type = EN;
  }
  return (
    <LangContext.Provider value={type}>{props.children}</LangContext.Provider>
  );
}
