import * as React from "react";
import { store } from "../../Redux/Store/Store";
import { setEmitter } from "../../Redux/Reducers";
import { AuthAPI } from "../../Apis";
import { useState } from "react";
import { Httpstatus } from "@piximind/api";
import { useParams } from "react-router-dom";
import { ChangePasswordTemplate } from "../../Templates";
import { LangContext } from "../../Lang/Provider/Provider";

export const ChangePassword = () => {
  const { token } = useParams<{ token: string }>();
  const auth = new AuthAPI();
  const lang = React.useContext(LangContext);
  const [newPassword, setNewPassword] = useState<string>("");
  const handleChangePassword = async () => {
    try {
      const response = await auth.changePassword(token!, {
        newPassword: newPassword,
      });
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successChangePassword,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };

  return (
    <ChangePasswordTemplate
      lang={lang}
      newPassword={newPassword}
      onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
        setNewPassword(e.target.value)
      }
      onClickButton={handleChangePassword}
    />
  );
};
