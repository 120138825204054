import { ELang, Table } from "@piximind/ds-p-23";
import { IListCompanies } from "../../Interfaces";
import { Data } from "../../Common";
import { SearchBarOrganism } from "../../Components/Organisms/SearchBar/SearchBar";
import { TitlePageMolecule } from "../../Components";

function CompaniesListTemplate({
  lang,
  isArchived,
  state,
  onKeyPress,
  onPaginate,
  onChange,
  onClick,
  elementData,
  numberOfPage,
  actionButtonModify,
  actionButtonArchive,
  actionButtonDelete,
  pageNumber,
  listSelectedElement,
  onSelect,
  onClickSelectEditIcon,
  onClickSelectArchiveIcon,
  onClickSelectDeleteIcon,
  actionButtonView,
  actionButtonViewArchive,
}: IListCompanies) {
  return (
    <div className="ds-flex-col ds-pl-25 ds-pt-28 ds-w-100">
      <TitlePageMolecule
        title={lang.page}
        subTitle={isArchived ? lang.archivedCompaniesList : lang.companiesList}
      />
      <div className="ds-justify-between ds-mt-20 ds-flex ds-w-100">
        <SearchBarOrganism
          isArchived={isArchived}
          isAddButton
          state={state}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onClickSelectEditIcon={onClickSelectEditIcon}
          onClickSelectArchiveIcon={onClickSelectArchiveIcon}
          onClickSelectDeleteIcon={onClickSelectDeleteIcon}
          listSelectedElement={listSelectedElement}
          lang={lang}
        />
      </div>
      <div className="border-solid ds-p-15 ds-mt-25 ds-mr-10">
        <Table
          className="ds-mt-27 ds-fs-mds"
          headers={Data.getDataTableCompanies()}
          data={elementData}
          withPagination
          withCheckox
          listSelectedElement={listSelectedElement}
          onSelect={onSelect}
          isRightPagination
          numberOfPage={numberOfPage}
          actions={[
            {
              label: lang.modify,
              icon: "pencil",
              onClick: actionButtonModify,
              isDisplayed: true,
            },
            {
              label: isArchived ? lang.unarchive : lang.archive,
              icon: isArchived ? "folder-open" : "server",
              onClick: actionButtonArchive,
              isDisplayed: true,
            },
            {
              label: lang.delete,
              icon: "trash",
              onClick: actionButtonDelete,
              isDisplayed: true,
            },
            {
              label: lang.viewProcessus,
              icon: "trash",
              onClick: actionButtonView,
              isDisplayed: true,
            },
            {
              label: lang.viewArchivedProcessus,
              icon: "trash",
              onClick: actionButtonViewArchive,
              isDisplayed: true,
            },
          ]}
          onPaginate={onPaginate}
          page={pageNumber}
          lang={ELang.fr}
        />
      </div>
    </div>
  );
}
export default CompaniesListTemplate;
