import { Input, Stepper, Text } from "@piximind/ds-p-23";
import { PreviousFollowingButtons, TitlePageMolecule } from "../../Components";
import { IManageCompanyTemplate } from "../../Interfaces";
import ManageUserTemplate from "../ManageUser/ManageUser";
import { Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
function ManageCompanyTemplate({
  lang,
  isUpdateCompany,
  step,
  companyManager,
  onClickFollowing,
  onClickPrevious,
  onChangeFirstNameRmq,
  onChangeLastNameRmq,
  onChangeMailRmq,
  onChangePasswordRmq,
  onChangePhoneNumberRmq,
  onChangeFirstNameManager,
  onChangeLastNameManager,
  onChangeMailManager,
  onChangePasswordManager,
  onChangePhoneNumberManager,
  onChangeCompanyName,
  onChangeCompanyRegistreNumber,
  onChangeCompanyYear,
  onChangeDateEnd,
  onChangeDateStart,
  selectOptionYears,
  isLoading,
}: IManageCompanyTemplate) {
  return (
    <div className="ds-p-30">
      <TitlePageMolecule
        title={lang.page}
        subTitle={
          isUpdateCompany
            ? lang.modifyCompany
            : step === 1
            ? lang.addRmq
            : step === 2
            ? lang.addManager
            : step >= 3
            ? lang.addCompanyInformation
            : ""
        }
      />
      {!isUpdateCompany && (
        <Stepper
          isVertical={false}
          data={[
            { text: lang.addRmq, title: lang.addRmq },
            { text: lang.addManager, title: lang.addManager },
            {
              text: lang.addCompanyInformation,
              title: lang.addCompanyInformation,
            },
          ]}
          step={step}
        />
      )}
      <div className="ds-w-100 border-solid ds-p-20">
        {step === 1 && !isUpdateCompany && (
          <ManageUserTemplate
            lang={lang}
            isUpdateUser={false}
            isUseInAnotherTemplate={true}
            userManager={companyManager.rmq}
            selectOptionRole={[]}
            onChangeFirstName={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFirstNameRmq && onChangeFirstNameRmq(e)
            }
            onChangeLastName={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeLastNameRmq && onChangeLastNameRmq(e)
            }
            onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeMailRmq && onChangeMailRmq(e)
            }
            onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePasswordRmq && onChangePasswordRmq(e)
            }
            onChangePhoneNumber={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePhoneNumberRmq && onChangePhoneNumberRmq(e)
            }
            onChangeRole={() => {}}
            onClickButton={() => {}}
            isLoading={false}
          />
        )}
        {step === 2 && !isUpdateCompany && (
          <ManageUserTemplate
            lang={lang}
            isUpdateUser={false}
            isUseInAnotherTemplate={true}
            userManager={companyManager.manager}
            selectOptionRole={[]}
            onChangeFirstName={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFirstNameManager && onChangeFirstNameManager(e)
            }
            onChangeLastName={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeLastNameManager && onChangeLastNameManager(e)
            }
            onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeMailManager && onChangeMailManager(e)
            }
            onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePasswordManager && onChangePasswordManager(e)
            }
            onChangePhoneNumber={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangePhoneNumberManager && onChangePhoneNumberManager(e)
            }
            onChangeRole={() => {}}
            onClickButton={() => {}}
            isLoading={false}
          />
        )}
        {step >= 3 && (
          <div>
            <div className="ds-flex">
              <Input
                label={lang.companyName}
                value={companyManager.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCompanyName && onChangeCompanyName(e)
                }
                isValid={companyManager.name !== ""}
                isInvalid={companyManager.name === ""}
              />
              <div className="ds-w-5" />
              <Input
                label={lang.year}
                isSelect
                selectOption={selectOptionYears}
                onChangeSelect={(e: string[]) =>
                  onChangeCompanyYear && onChangeCompanyYear(e)
                }
                isMulti
                selectValue={companyManager.yearsName}
                isValid={companyManager.yearsName.length !== 0}
                isInvalid={companyManager.yearsName.length === 0}
              />
            </div>
            <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
              <Text className="ds-mt-5 ds-w-19" text={lang.registreNumber} />
              <Form.Control
                className="ds-ml-20 ds-mb-10"
                size="lg"
                type="number"
                min={0}
                style={{ width: "400px", fontSize: "15px" }}
                value={companyManager.registrationNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCompanyRegistreNumber &&
                  onChangeCompanyRegistreNumber(e)
                }
                isValid={companyManager.registrationNumber !== 0}
                isInvalid={companyManager.registrationNumber === 0}
              />
            </div>
            <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
              <Text className="ds-mt-5 ds-w-20" text={lang.contratStartDate} />
              <DatePicker
                onChange={onChangeDateStart}
                value={companyManager.subscriptionStartDate}
              />
            </div>
            <div className="ds-flex ds-w-100 ds-m-10 ds-mt-30">
              <Text className="ds-mt-5 ds-w-20" text={lang.contratEndDate} />
              <DatePicker
                onChange={onChangeDateEnd}
                value={companyManager.subscriptionEndDate}
              />
            </div>
          </div>
        )}
      </div>
      <PreviousFollowingButtons
        isPrevious={!isUpdateCompany && step > 1}
        textButtonPrevious={lang.previous}
        onClickPrevious={onClickPrevious}
        textButtonFollowing={step < 3 ? lang.next : lang.submit}
        onClickFollowing={onClickFollowing}
        isLoading={isLoading}
      />
    </div>
  );
}
export default ManageCompanyTemplate;
