import { useEffect, useState } from "react";
import { TaskAPI } from "../../Apis";
import { useNavigate, useParams } from "react-router-dom";
import { Httpstatus } from "@piximind/api";
import { ITaskModel } from "../../Interfaces";
import { EditTaskTemplate } from "../../Templates";
import { store } from "../../Redux/Store/Store";
import { setEmitter } from "../../Redux/Reducers";
import { LangContext } from "../../Lang/Provider/Provider";
import React from "react";

export const EditTask = () => {
  const lang = React.useContext(LangContext);
  const taskApi = new TaskAPI();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [task, setTask] = useState<ITaskModel>();
  const [taskValue, setTaskValue] = useState<string>("");
  const [comment, setComment] = useState<string | undefined>();
  useEffect(() => {
    getTaskById();
  }, []);
  const getTaskById = async () => {
    const response = await taskApi.getTaskById(id!);
    if (response.status === Httpstatus.SuccessOK) {
      const task = response.data?.task;
      setTaskValue(task?.name);
      setTask(task!);
      setComment(task?.comment)
    } else {
      navigate("/");
    }
  };
  const handleEditTask = async () => {
    try {
      const response = await taskApi.updateTask({
        id: id!,
        name: taskValue,
        comment,
      });
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successUpdateEnregistrement,
            position: "top-center",
          })
        );
        navigate(`/taskslist/${task?.epicId}`);
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.errorUpdateEnregistrement,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  return (
    <EditTaskTemplate
      lang={lang}
      task={task!}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setTaskValue(e.target.value);
      }}
      onClickEditTask={() => handleEditTask()}
      taskValue={taskValue}
      comment={comment}
      onChangeComment={(e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
      }}
    />
  );
};
