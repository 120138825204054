import { useUpdateEffect } from "@piximind/custom-hook";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IOpenToast, IRootState } from "../../Interfaces";
import { setEmitterClear } from "../../Redux/Reducers";

export const ToastProvider = () => {
  const toastSetting = useSelector((state: IRootState) => state?.emitter);
  const dispatch = useDispatch();
  const openToast = ({ toastState, toastMessage, fn }: IOpenToast) => {
    let functionToCall = null;
    switch (fn) {
      case "info":
        functionToCall = toast.info;
        break;
      case "success":
        functionToCall = toast.success;
        break;
      case "warn":
        functionToCall = toast.warn;
        break;
      case "error":
        functionToCall = toast.error;
        break;
      default:
        functionToCall = toast;
        break;
    }
    if (functionToCall) {
      functionToCall(toastMessage, toastState);
    }
  };
  useUpdateEffect(() => {
    if (toastSetting) {
      openToast({
        fn: toastSetting?.type,
        toastState: toastSetting?.data,
        toastMessage: toastSetting?.message,
      });
      dispatch(setEmitterClear());
    }
  }, [toastSetting]);
  return <></>;
};
