import React from "react";
import langFr from "../Fr/fr";
import langEn from "../En/en";
import { IKeys } from "../../Interfaces";
const LANG = {
  fr: langFr,
  en: langEn,
};
const LangContext = React.createContext<IKeys>(langFr);
export { LANG, LangContext };
