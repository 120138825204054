import { Link, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Container, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetCredentials } from "../../../Redux/Reducers";
import React from "react";
import { LangContext } from "../../../Lang/Provider/Provider";

const NavBarOrganism = (): JSX.Element => {
  const lang = React.useContext(LangContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogOutClick = () => {
    dispatch(resetCredentials());
  };
  const navItemsAndLinks = [
    {
      name: "Ajouter",
      sublinks: [
        { name: "Utilisateur", link: "/manageuser" },
        { name: "Entreprise", link: "/managecompany" },
      ],
    },
    {
      name: "Listes",
      sublinks: [
        { name: "Utilisateurs", link: "/userslist" },
        { name: "Entreprises", link: "/companieslist" },
        { name: "Utilisateurs archivées", link: "/archiveuserslist" },
        { name: "Entreprises archivées", link: "/archivecompanieslist" },
      ],
    },
  ];

  return (
    <div className="ds-flex-grow1 ds-w-100">
      <Navbar className="ds-bg-color-mds" variant="dark">
        <Navbar.Brand
          className="ds-p-10 ds-pointer"
          onClick={() => navigate("/")}
        >
          <img src="" />
          {lang.mdsConsulting}
        </Navbar.Brand>
        <Container>
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {navItemsAndLinks.map((item, index) => {
              return (
                <div key={index}>
                  {item.sublinks && (
                    <NavDropdown title={item.name} id={item.name}>
                      {item.sublinks.map((sublink, i) => (
                        <NavDropdown.Item
                          className="ds-nav-link"
                          key={i}
                          as={Link}
                          to={sublink.link}
                        >
                          {sublink.name}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  )}
                </div>
              );
            })}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Button
              onClick={onLogOutClick}
              variant="danger"
              className="ds-bg-red"
            >
              {lang.logout}
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export { NavBarOrganism };
