import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISearch, IUserModel } from "../../Interfaces";
import { CompanyAPI, EpicAPI, TaskAPI, UserAPI } from "../../Apis";
import { IListData } from "@piximind/ds-p-23/lib/esn/Interfaces/Organisms/IOrganismTable/IOrganismTable";
import { store } from "../../Redux/Store/Store";
import { openModal, setEmitter } from "../../Redux/Reducers";
import { Httpstatus } from "@piximind/api";
import { TasksListTemplate } from "../../Templates";
import React from "react";
import { LangContext } from "../../Lang/Provider/Provider";

export const TasksList = () => {
  const lang = React.useContext(LangContext);
  let counter: number = 1;
  const navigate = useNavigate();
  const tasksApi = new TaskAPI();
  const epicApi = new EpicAPI();
  const companyApi = new CompanyAPI();
  const userApi = new UserAPI();
  const { id } = useParams<{ id: string }>();
  const { idCompany } = useParams<{ idCompany: string }>();
  const [tasks, setTasks] = useState<IListData>([]);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [newTaskValue, setNewTaskValue] = useState<string>("");
  const [epicValue, setEpicValue] = useState<string>("");
  const [epicPercentage, setEpicPercentage] = useState<number>(0);
  const [search, setSearch] = useState<ISearch>({
    pageNumber: 1,
    perPage: 8,
    searchWord: "",
    isArchived: false,
    id: id!,
  });
  const [lisSelectElement, setListSelectElement] = useState<string[]>([]);
  const [selectOptionUsers, setSelectOptionUsers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [selectedOptionsValue, setselectedOptionsValue] = useState<string[]>(
    []
  );
  useEffect(() => {
    getTasks();
    getCompanyUsers();
  }, []);
  useEffect(() => {
    getTasks();
  }, [search.pageNumber]);
  const getTasks = async () => {
    const response = await tasksApi.searchTasks(search);
    if (response.status === 200) {
      getUsersIdsByEpicId();
      const tasks = response.data.tasks;
      const epic = response.data.epic;
      setEpicValue(epic?.name);
      setEpicPercentage(epic?.percentage);
      const result = tasks.map((task: any) => {
        return {
          id: task?._id,
          name: task?.name,
          createdAt: task?.createdAt,
          percentage: task?.state + "%",
          createdBy:
            task?.createdBy?.firstName + " " + task?.createdBy?.lastName,
          _id: counter++,
        };
      });
      setTasks(result);
      setNumberOfPages(response.data.numberOfPages);
    } else {
      navigate("/");
    }
  };
  const getUsersIdsByEpicId = async () => {
    try {
      const response = await userApi.getUsersIdsByEpicId(id!);
      if (response.status === 200) {
        const usersIds: string[] = response.data?.usersIds;
        setselectedOptionsValue(usersIds);
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const getCompanyUsers = async () => {
    try {
      const response = await companyApi.getCompanyUsers(idCompany!);
      if (response.status === 200) {
        const users: IUserModel[] = response.data?.users;
        const selectOptionUsers: {
          label: string;
          value: string;
        }[] = users.map((user) => {
          return {
            label: user.firstName + " " + user.lastName,
            value: user._id!,
          };
        });
        setSelectOptionUsers(selectOptionUsers);
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const deleteTasks = async (list: Array<string>) => {
    try {
      const response = await tasksApi.deleteTasks({
        ids: list,
        isArchived: true,
      });
      getTasks();
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successDeleteEnregistrement,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  function openUrlsInNewWindows(ids: Array<string>) {
    ids.forEach((id) => {
      window.open(`edittask/${id}`, "_blank");
    });
  }
  const handleSearchQueryChange = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (search.searchWord === "") {
        getTasks();
      } else {
        setSearch({ ...search, pageNumber: 1 });
        getTasks();
      }
    }
  };
  const handleConfirmAssignTo = async () => {
    try {
      const response = await userApi.addEpicIdToUsers({
        usersIds: selectedOptionsValue,
        epicId: id!,
      });
      if (response.status == 200) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successAssigneTo,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const handleAddTask = async () => {
    try {
      if (id !== "") {
        const response = await tasksApi.createTask({
          epicId: id!,
          name: newTaskValue,
        });
        if (
          response.status === Httpstatus.SuccessOK ||
          response.status === Httpstatus.SuccessCreated
        ) {
          setNewTaskValue("");
          getTasks();
          store.dispatch(
            setEmitter({
              type: "success",
              message: lang.successAddEnregistrement,
              position: "top-center",
            })
          );
        } else {
          store.dispatch(
            setEmitter({
              type: "error",
              message: lang.error,
              position: "top-center",
            })
          );
        }
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  const handleEditEpicTask = async () => {
    try {
      if (id !== "") {
        const response = await epicApi.updateEpic({ id: id!, name: epicValue });
        if (response.status === Httpstatus.SuccessOK) {
          store.dispatch(
            setEmitter({
              type: "success",
              message: lang.successUpdateEnregistrement,
              position: "top-center",
            })
          );
        } else {
          store.dispatch(
            setEmitter({
              type: "error",
              message: lang.error,
              position: "top-center",
            })
          );
        }
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };
  return (
    <TasksListTemplate
      onSelect={(list: string[]) => {
        setListSelectElement(list);
      }}
      newTaskValue={newTaskValue}
      onClickSelectEditIcon={() => {
        openUrlsInNewWindows(lisSelectElement);
      }}
      onChangeTaskValue={(e: any) => {
        setNewTaskValue(e.target.value);
      }}
      onClickAddTask={handleAddTask}
      state={search.searchWord}
      onChange={(e: any) => {
        setSearch({ ...search, searchWord: e.target.value });
      }}
      onKeyPress={handleSearchQueryChange}
      elementData={tasks}
      numberOfPage={numberOfPages}
      actionButtonModify={(e: any, i: any) => {
        navigate(`/editetask/${tasks[i].id}`);
      }}
      actionButtonArchive={() => {}}
      actionButtonDelete={(e: any, i: any) => {
        store.dispatch(
          openModal({
            name: "genericModalRef",
            data: {
              title: "Supprimer enregistrement",
              message: "Est ce que vous etes sur de supprimer enregistrement",
              submitText: "Supprimer",
              cancelText: "Annuler",
              submit: async () => await deleteTasks([tasks[i].id.toString()]),
            },
          })
        );
      }}
      onPaginate={(e: number) => {
        setSearch({ ...search, pageNumber: e });
      }}
      pageNumber={search.pageNumber}
      listSelectedElement={lisSelectElement}
      isArchived={false}
      epicValue={epicValue}
      onChangeEpicValue={(e: any) => {
        setEpicValue(e.target.value);
      }}
      onClickEpicEdit={handleEditEpicTask}
      epicPercentage={epicPercentage}
      lang={lang}
      selectOptionUsers={selectOptionUsers}
      selectedOptionsValue={selectedOptionsValue}
      onChangeSelectedOptionsValue={(e: string[]) => {
        setselectedOptionsValue(e);
      }}
      onClickConfirmAssignTo={handleConfirmAssignTo}
    />
  );
};
