import { TextType } from "@piximind/ds-p-23"
import { Icon, Text } from "../../DesignSystem"
import { IGenericModalOrganism } from "../../Interfaces/Modal"

const GenericModalOrganism = (props: IGenericModalOrganism): JSX.Element => {
  return (
    <div className="ds-flex-col ds-align-center ds-w-100">
      <Icon icon="exclamation-circle" className={"ds-text-warning500"} size={'100px'} />
      <Text text={props.title} type={TextType["type-6"]} />
      <Text text={props.message} className="ds-justify-center" />
    </div>
  )
}
export default GenericModalOrganism
