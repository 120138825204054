import { ELang, Table } from "@piximind/ds-p-23";
import { IListeTemplate } from "../../Interfaces";
import { Data } from "../../Common";
import { SearchBarOrganism } from "../../Components/Organisms/SearchBar/SearchBar";
import { TitlePageMolecule } from "../../Components";

function UsersListTemplate({
  lang,
  isArchived,
  state,
  onKeyPress,
  onPaginate,
  onChange,
  onClick,
  elementData,
  numberOfPage,
  actionButtonModify,
  actionButtonArchive,
  actionButtonDelete,
  pageNumber,
  listSelectedElement,
  onSelect,
  onClickSelectEditIcon,
  onClickSelectArchiveIcon,
  onClickSelectDeleteIcon,
}: IListeTemplate) {
  return (
    <div className="ds-flex-col ds-pl-25 ds-pt-28 ds-w-100">
      <TitlePageMolecule
        title={lang.page}
        subTitle={
          isArchived
            ? lang.archiveUsersListe
            : lang.usersListe
        }
      />
      <div className="ds-justify-between ds-flex ds-w-100">
        <SearchBarOrganism
          lang={lang}
          isArchived={isArchived}
          isAddButton
          state={state}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onClickSelectEditIcon={onClickSelectEditIcon}
          onClickSelectArchiveIcon={onClickSelectArchiveIcon}
          onClickSelectDeleteIcon={onClickSelectDeleteIcon}
          listSelectedElement={listSelectedElement}
        />
      </div>
      <div className="border-solid ds-p-15 ds-mt-25 ds-mr-10">
        <Table
          className="ds-mt-27 ds-checkbox:checked ds-fs-mds"
          headers={Data.getDataTableUsers()}
          data={elementData}
          withPagination
          withCheckox
          listSelectedElement={listSelectedElement}
          onSelect={onSelect}
          isRightPagination
          numberOfPage={numberOfPage}
          actions={[
            {
              label: lang.edit,
              icon: "pencil",
              onClick: actionButtonModify,
              isDisplayed: true,
            },
            {
              label: lang.archive,
              icon: isArchived ? "folder-open" : "server",
              onClick: actionButtonArchive,
              isDisplayed: true,
            },
            {
              label: lang.delete,
              icon: "trash",
              onClick: actionButtonDelete,
              isDisplayed: true,
            },
          ]}
          onPaginate={onPaginate}
          page={pageNumber}
          lang={ELang.fr}
        />
      </div>
    </div>
  );
}
export default UsersListTemplate;
