enum tokensEnum {
  noToken = "noToken",
  accessToken = "accessToken",
  refreshToken = "refreshToken",
}
enum UserRoleTypes {
  admin = "admin",
  manager = "manager",
  rmq = "rmq",
  user = "user",
}

export { tokensEnum, UserRoleTypes };
