import { Button, ESizeInput, Input, Text } from "@piximind/ds-p-23";
import { Link } from "react-router-dom";
import { Copyright } from "../../Atoms";
import { IPublicOrganism } from "../../../Interfaces";
import logo from "../../../Style/Logo/Logo.svg";
const PublicOrganism = ({
  isSignIn,
  isChangePassword,
  isResetPassword,
  mail,
  password,
  lang,
  onChangeMail,
  onChangePassword,
  onClickButtonAuth,
  isValidMail,
  isValidPassword,
  isFormValid,
}: IPublicOrganism): JSX.Element => {
  return (
    <div className="ds-flex-col ds-center ds-h-100 ds-mt-50">
      {(isChangePassword || isSignIn || isResetPassword) && (
        <div className="box">
          <div className="border-solid ds-w-100 ds-p-30">
            <div className="ds-flex-col ds-center">
              <img src={logo} style={{ width: 200 }} className="ds-mb-32" />
              <Text
                text={
                  isSignIn
                    ? lang.loginAdminMds
                    : isChangePassword
                    ? lang.changePassword
                    : lang.sendMailChangePassword
                }
                className="text-center ds-hp-28 ds-text-weight700 ds-text-size-24 ds-text-line-28 ds-color-mds"
              />
              {(isSignIn || isResetPassword) && (
                <Input
                  labelClassName="ds-text-weight400 ds-text-size-16 ds-text-line-28"
                  inputSize={ESizeInput.large}
                  value={mail}
                  label={lang.mailAdress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeMail && onChangeMail(e)
                  }
                  isValid={isValidMail}
                  isInvalid={!isValidMail}
                />
              )}
              {(isSignIn || isChangePassword) && (
                <Input
                  labelClassName="ds-text-weight400 ds-text-size-16 ds-text-line-28"
                  inputSize={ESizeInput.large}
                  label={isSignIn ? lang.password : lang.newPassword}
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangePassword && onChangePassword(e)
                  }
                  isPassword
                  isValid={isValidPassword}
                  isInvalid={!isValidPassword}
                />
              )}
              {
                <Link
                  to={isSignIn ? "/resetpassword" : "/"}
                  className="ds-text-size-12 ds-text-weight400 ds-text-line-28 ds-mt-8 ds-flex ds-justify-end ds-color-error"
                >
                  <Text
                    text={isSignIn ? lang.forgotPassword : lang.returnLoginPage}
                  />
                </Link>
              }
              <Button
                className="ds-w-100 ds-mt-10 ds-mb-10 ds-color-black ds-bg-mds"
                text={
                  isSignIn
                    ? lang.login
                    : isChangePassword
                    ? lang.changePassword
                    : lang.sendMail
                }
                onClick={onClickButtonAuth}
                disabled={!isFormValid}
              />
              <Copyright />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export { PublicOrganism };
