import { useEffect, useState } from "react";
import { ManageUserTemplate } from "../../Templates";
import { ICompanyModel, ICreateUser } from "../../Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyAPI, UserAPI } from "../../Apis";
import { store } from "../../Redux/Store/Store";
import { setEmitter } from "../../Redux/Reducers";
import { Data } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import { Httpstatus } from "@piximind/api";
import React from "react";
import { LangContext } from "../../Lang/Provider/Provider";

export const ManageUser = () => {
  const lang = React.useContext(LangContext);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isUpdateUser, setIsUpdateUser] = useState<boolean>(false);
  const userApi = new UserAPI();
  const companyApi = new CompanyAPI();
  const selectOptionRoles: Array<{
    label: string;
    value: string;
  }> = Data.getDataRoles();
  const [userManager, setUserManager] = useState<ICreateUser>({
    firstName: "",
    lastName: "",
    mail: "",
    password: "",
    role: "",
    phoneNumber: "",
    isRmq: false,
  });
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [isChangeForm, setIsChangeForm] = useState<boolean>(false);
  const [selectOptionCompany, setSelectOptionCompany] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    getAllCompanies();
    verifId();
  }, []);
  useEffect(() => {
    isChangeForm &&
      userManager.role === "admin" &&
      setUserManager({ ...userManager, companyId: undefined });
  }, [userManager.role]);
  const getAllCompanies = async () => {
    const response = await companyApi.getAllCompanies();
    if (response.status === 200) {
      const companies: ICompanyModel[] = response.data?.companies;
      const result = companies?.map((company: ICompanyModel) => {
        return {
          label: company?.name,
          value: company?._id!,
        };
      });
      setSelectOptionCompany(result);
    }
  };
  const verifId = async () => {
    if (id != "" || undefined) {
      try {
        const response = await userApi.getUserById(id ?? "");
        if (response.status === 200) {
          setIsUpdateUser(true);
          const user = response?.data.user;
          setUserManager({ ...user, companyId: user?.companyId?._id });
          setSelectedCompany(user?.companyId?._id!);
          for (const company of selectOptionCompany) {
            if (company.value === userManager.companyId) {
              setSelectedCompany(company.label);
            }
          }
        } else {
          setIsChangeForm(true);
          navigate("/manageuser");
        }
      } catch (err) {
        isUpdateUser &&
          store.dispatch(
            setEmitter({
              type: "error",
              message: lang.error,
              position: "top-center",
            })
          );
      }
    }
  };
  const handleCreateUpdateUser = async () => {
    setIsLoading(true);
    let response: ApiResponse;
    response = isUpdateUser
      ? await userApi.updateUser({
          id: id!,
          ...userManager,
        })
      : await userApi.createUser(userManager);
    if (
      response.status === Httpstatus.SuccessCreated ||
      response.status === Httpstatus.SuccessOK
    ) {
      store.dispatch(
        setEmitter({
          type: "success",
          message: isUpdateUser ? lang.successAddUser : lang.successUpdateUser,
          position: "top-center",
        })
      );
      navigate("/userslist");
    } else {
      store.dispatch(
        setEmitter({
          type: "error",
          message: isUpdateUser ? lang.errorUpdateUser : lang.errorCreateUser,
          position: "top-center",
        })
      );
    }
    setIsLoading(false);
  };
  return (
    <ManageUserTemplate
      lang={lang}
      isUpdateUser={isUpdateUser}
      isUseInAnotherTemplate={false}
      userManager={userManager}
      selectOptionRole={selectOptionRoles}
      onChangeFirstName={(e: React.ChangeEvent<HTMLInputElement>) =>
        setUserManager({ ...userManager, firstName: e.target.value })
      }
      onChangeLastName={(e: React.ChangeEvent<HTMLInputElement>) =>
        setUserManager({ ...userManager, lastName: e.target.value })
      }
      onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
        setUserManager({ ...userManager, mail: e.target.value })
      }
      onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
        setUserManager({ ...userManager, password: e.target.value })
      }
      onChangePhoneNumber={(e: React.ChangeEvent<HTMLInputElement>) =>
        setUserManager({ ...userManager, phoneNumber: e.target.value })
      }
      onChangeRole={(e: string[]) => {
        setUserManager({ ...userManager, role: e[0] });
        !isChangeForm && setIsChangeForm(true);
      }}
      onClickButton={handleCreateUpdateUser}
      selectOptionCompany={selectOptionCompany}
      selectedCompany={selectedCompany}
      onChangeCompany={(e: string[]) => {
        setUserManager({ ...userManager, companyId: e[0] });
        for (const company of selectOptionCompany) {
          if (company.value === e[0]) {
            setSelectedCompany(company.value);
          }
        }
      }}
      isLoading={isLoading}
    />
  );
};
