import { Text } from "@piximind/ds-p-23";
import { LangContext } from "../../Lang/Provider/Provider";
import React from "react";

export const NotFound = () => {
  const lang = React.useContext(LangContext);
  return (
    <div className="ds-flex ds-center ds-h-100 ds-w-100">
      <div className="ds-flex-col">
        <Text text={lang.errorPageNotFound} size={40} />
      </div>
    </div>
  );
};
