import SignInTemplate from "./SignIn/SignIn";
import CompaniesListTemplate from "./CompaniesList/CompaniesList";
import UsersListTemplate from "./UsersList/UsersList";
import ManageCompanyTemplate from "./ManageCompany/ManageCompany";
import ManageUserTemplate from "./ManageUser/ManageUser";
import ChangePasswordTemplate from "./ChangePassword/ChangePassword";
import ResetPasswordTemplate from "./ResetPassword/ResetPassword";
import TasksListTemplate from "./TasksList/TasksList";
import EditTaskTemplate from "./EditTask/EditTask";
export {
  SignInTemplate,
  CompaniesListTemplate,
  UsersListTemplate,
  ManageCompanyTemplate,
  ManageUserTemplate,
  ChangePasswordTemplate,
  ResetPasswordTemplate,
  TasksListTemplate,
  EditTaskTemplate,
};
