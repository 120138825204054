import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import {
  IArchiveDelete,
  ICreateTask,
  ISearch,
  IUpdateTask,
} from "../../Interfaces";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class TaskAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/task");
  }
  async createTask(payload: ICreateTask): Promise<ApiResponse> {
    const response = await this._api.post(
      "create",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async getTaskById(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `gettaskbyid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async updateTask(payload: IUpdateTask): Promise<ApiResponse> {
    const response = await this._api.put(
      "update",
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
  async searchTasks(payload: ISearch): Promise<ApiResponse> {
    const url = `getalltasksfiltre/?pageNumber=${payload.pageNumber}&perPage=${payload.perPage}&searchWord=${payload.searchWord}&isArchived=${payload.isArchived}&id=${payload.id}`;
    return await this._api.get(url, handleHeaders(tokensEnum.accessToken));
  }
  async deleteTasks(payload: IArchiveDelete): Promise<ApiResponse> {
    return await this._api.delete(
      `deletetasks`,
      JSON.stringify(payload),
      handleHeaders(tokensEnum.accessToken)
    );
  }
}
export { TaskAPI };
