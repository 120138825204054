import * as React from "react";
import { ResetPasswordTemplate } from "../../Templates";
import { store } from "../../Redux/Store/Store";
import { setEmitter } from "../../Redux/Reducers";
import { AuthAPI } from "../../Apis";
import { useState } from "react";
import { Httpstatus } from "@piximind/api";
import { LangContext } from "../../Lang/Provider/Provider";

export const ResetPassword = () => {
  const lang = React.useContext(LangContext);
  const auth = new AuthAPI();
  const [mail, setMail] = useState<string>("");
  const handleResetPassword = async () => {
    try {
      const response = await auth.resetPassword({ mail: mail });
      if (response.status === Httpstatus.SuccessOK) {
        store.dispatch(
          setEmitter({
            type: "success",
            message: lang.successSendMail,
            position: "top-center",
          })
        );
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.verifMailAdress,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };

  return (
    <ResetPasswordTemplate
      lang={lang}
      mail={mail}
      onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
        setMail(e.target.value)
      }
      onClickButton={handleResetPassword}
    />
  );
};
