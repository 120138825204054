import * as React from "react";
import { SignInTemplate } from "../../Templates";
import { store } from "../../Redux/Store/Store";
import { setCredentials, setEmitter } from "../../Redux/Reducers";
import { AuthAPI } from "../../Apis";
import { useState } from "react";
import { ILoginForm } from "../../Interfaces";
import { Httpstatus } from "@piximind/api";
import { setUser } from "../../Redux/Reducers/Auth";
import { useDispatch } from "react-redux";
import { LangContext } from "../../Lang/Provider/Provider";

export const SignIn = () => {
  const lang = React.useContext(LangContext);
  const dispatch = useDispatch();
  const auth = new AuthAPI();
  const [loginForm, setLoginForm] = useState<ILoginForm>({
    mail: "",
    password: "",
  });
  const handleLogin = async () => {
    try {
      const response = await auth.login({
        mail: loginForm.mail,
        password: loginForm.password,
        isAdmin: true,
      });

      if (response.status === Httpstatus.SuccessOK) {
        dispatch(setCredentials(response.data));
        dispatch(setUser(response.data.user));
      } else {
        store.dispatch(
          setEmitter({
            type: "error",
            message: lang.error,
            position: "top-center",
          })
        );
      }
    } catch (err) {
      store.dispatch(
        setEmitter({
          type: "error",
          message: lang.error,
          position: "top-center",
        })
      );
    }
  };

  return (
    <SignInTemplate
      lang={lang}
      loginForm={loginForm}
      onChangeMail={(e: React.ChangeEvent<HTMLInputElement>) =>
        setLoginForm({ ...loginForm, mail: e.target.value })
      }
      onChangePassword={(e: React.ChangeEvent<HTMLInputElement>) =>
        setLoginForm({ ...loginForm, password: e.target.value })
      }
      onClickButton={handleLogin}
    />
  );
};
