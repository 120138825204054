class Config {
  SOCKET_URL = "";
  API_URL = "https://backend.mds-follow.com";
  private static instance: Config;
  constructor() {}
  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }
}

export { Config };
