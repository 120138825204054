import AuthReducer, { setCredentials, resetCredentials } from "./Auth";
import ModalReducer, { closeModal, openModal } from "./Modal";
import ToastReducer, { setEmitter, setEmitterClear } from "./Toast";
import SettingReducer, { setLang } from "./SettingReducer/SettingReducer";

export {
  AuthReducer,
  setCredentials,
  resetCredentials,
  ModalReducer,
  closeModal,
  openModal,
  ToastReducer,
  setEmitter,
  setEmitterClear,
  setLang,
  SettingReducer
};
