import { Api } from "@piximind/api";
import { Config } from "../../Common";
import { ApiResponse } from "@piximind/api/lib/types";
import { handleHeaders } from "../../Common/Function/headers";
import { tokensEnum } from "../../Enum";

class YearAPI {
  _api: Api;
  constructor() {
    this._api = new Api(Config.getInstance().API_URL + "/year");
  }
  async getYearById(id: string): Promise<ApiResponse> {
    const response = await this._api.get(
      `getyearbyid/${id}`,
      handleHeaders(tokensEnum.accessToken)
    );
    return response;
  }
}
export { YearAPI };
