import { createSlice } from "@reduxjs/toolkit";
import { IToastReducer } from "../../../Interfaces";

const initialState: IToastReducer = {
  type: null,
  message: null,
  data: null,
};

const ToastReducer = createSlice({
  name: "emitter",
  initialState: initialState,
  reducers: {
    setEmitter(state: IToastReducer, action) {
      state.type = action.payload.type;
      state.message = action.payload.message;
      const data = { ...action.payload };
      delete data.type;
      delete data.message;
      state.data = data;
    },
    setEmitterClear: (state: IToastReducer) => {
      state.type = null;
      state.message = null;
      state.data = null;
    },
  },
});

export const { setEmitter, setEmitterClear } = ToastReducer.actions;

export default ToastReducer.reducer;
