import React from "react";
import { NavBarOrganism } from "../../Components";
import { Outlet } from "react-router-dom";

const PrivateRoute = (): JSX.Element => {
  return (
    <div className="ds-w-100">
      <NavBarOrganism />
      <Outlet />
    </div>
  );
};

export default PrivateRoute;
