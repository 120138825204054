import { Text, Icon } from "@piximind/ds-p-23";
import { ITextTitleSubTitlesOrganism } from "../../../Interfaces";

const TitlePageMolecule = ({
  textSubTitleClassName,
  textTitleClassName,
  organismClassName,
  title,
  subTitle,
  isIcon,
  icon,
  iconClassName,
}: ITextTitleSubTitlesOrganism): JSX.Element => {
  return (
    <div className={`ds-flex ${organismClassName}`}>
      {isIcon && (
        <Icon className={`ds-mr-10 ${iconClassName}`} icon={icon} size={20} />
      )}
      <Text className={`ds-mr-10 ds-text-size-18 ${textTitleClassName}`} text={title} />
      <Icon className="ds-mr-10" icon="chevron-right" />
      <Text
        className={`ds-text-primary700 ds-text-size-18 ${textSubTitleClassName} ds-color-mds`}
        text={subTitle}
      />
    </div>
  );
};
export { TitlePageMolecule };
