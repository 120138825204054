import { Button, Text, ModalRefType } from "@piximind/ds-p-23"
import { Modal } from "../../DesignSystem"
import { IValidationModal } from "../../Interfaces/Modal"
import { Ref, forwardRef } from "react"

const ValidationModal = (props: IValidationModal, ref: Ref<ModalRefType | undefined>) => {
  const onClose = () => {
    if (props.data?.action) {
      props.data?.action()
    }
    if (props.onExit) {
      props.onExit()
    }
  }
  return (
    <Modal
      ref={ref}
      onExit={props.onExit}
      onShow={props.onShow}
      contentClassName="ds-wp-10 ds-hp-200 ds-justify-start"
    >
      <Text text={props.data?.title} />
      <Text text={props.data?.message} />
      <Button text={props.data?.btnText} onClick={onClose} />
    </Modal>
  )
}
export default forwardRef(ValidationModal)
