import { Button, SizeButton, TypeButton } from "@piximind/ds-p-23";
import { IPreviousFollowingButtons } from "../../../Interfaces";

const PreviousFollowingButtons = ({
  isPrevious,
  textButtonPrevious,
  onClickPrevious,
  textButtonFollowing,
  onClickFollowing,
  isDisabledButtonFllowing,
  buttonPreviousClassName,
  buttonFollowingClassName,
  className,
  isLoading,
}: IPreviousFollowingButtons) => {
  return (
    <div
      className={`ds-flex ds-w-100 ds-mt-30 ${className} ${
        !isPrevious ? `ds-justify-end` : "ds-justify-between"
      }`}
    >
      {isPrevious && (
        <Button
          className={buttonPreviousClassName}
          text={textButtonPrevious}
          type={TypeButton.secondary}
          size={SizeButton.medium}
          onClick={onClickPrevious}
        />
      )}
      <Button
        className={buttonFollowingClassName}
        text={textButtonFollowing}
        type={TypeButton.primary}
        size={SizeButton.medium}
        onClick={onClickFollowing}
        disabled={isDisabledButtonFllowing ? true : false}
        isLoading={isLoading}
      />
    </div>
  );
};
export default PreviousFollowingButtons;
