class CommonValidation {
  private static instance: CommonValidation;
  constructor() {}
  public static getInstance(): CommonValidation {
    if (!CommonValidation.instance) {
      CommonValidation.instance = new CommonValidation();
    }
    return CommonValidation.instance;
  }
  public static isMail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }
  public static isValidPassword(password: string): boolean {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[&"()]).+$/;
    return passwordRegex.test(password);
  }
  
  public static isPhoneNumber(phoneNumber: string): boolean {
    const phoneNumberRegex = /^[0-9]{8,}$/;
    return phoneNumberRegex.test(phoneNumber);
  }
  
}

export { CommonValidation };
